import React from 'react'
import styled from 'styled-components'
import ReachTooltip from '@reach/tooltip'
import { FiInfo } from 'react-icons/fi'

const Tooltip = ({ label }) => {
  return (
    <>
      <ReachTooltip label={label}>
        <TooltipContainer tabIndex='0' onClick={e => e.stopPropagation()}>
          <FiInfo aria-hidden='true' focusable='false' role='presentation' />
        </TooltipContainer>
      </ReachTooltip>
    </>
  )
}

const TooltipContainer = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-left: var(--size-xxs);

  svg {
    color: var(--color-n100);
  }

  @media print {
    display: none;
  }
`

export default Tooltip
