import { atom } from 'recoil'

export const WARRANTY_PERCENT_DISCOUNT_PROMOTIONS_STATE =
  'WARRANTY_PERCENT_DISCOUNT_PROMOTIONS_STATE'

const warrantyPercentDiscountPromotionsState = atom({
  key: WARRANTY_PERCENT_DISCOUNT_PROMOTIONS_STATE,
  default: [],
})

export default warrantyPercentDiscountPromotionsState
