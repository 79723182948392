import { atom } from 'recoil'
import { FINANCING_PROMOTION_DEFAULT } from '../constants'

export const FINANCING_STATE = 'FINANCING_STATE'

const financingState = atom({
  key: FINANCING_STATE,
  default: FINANCING_PROMOTION_DEFAULT,
})

export default financingState
