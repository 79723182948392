import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import Content from 'components/content'
import Disclaimer from 'components/disclaimer'
import UnstyledButton from 'components/unstyled-button'
import OptionList from 'components/option-list/option-list'
import OptionListItem from 'components/option-list/option-list-item'
import OptionListSection from 'components/option-list/option-list-section'
import Spacer from 'components/spacer'
import Button from 'components/button'
import { Tooltip } from 'components/tooltip'
import {
  ATTACHMENTS_PANEL,
  ATTACHMENTS_TAB,
  FINANCING_PANEL,
  FINANCING_PROMOTION_DEFAULT,
  FINANCING_TAB,
  IMPLEMENTS_PANEL,
  IMPLEMENTS_TAB,
  OPTIONS_PANEL,
  OPTIONS_TAB,
  SUMMARY_PANEL,
  SUMMARY_TAB,
  WARRANTY_PANEL,
  WARRANTY_TAB,
} from '../../constants'
import { formatWarranty } from '../../utils'
import { formatPrice, isDefined, isNumber } from 'utils'
import { fbq, fbqEvents, gtag, gtagEvents } from '@hutson/utils'
import { clearfix, column } from 'styles'
import { useFinancing, useTab } from '../../hooks'
import {
  grandTotalSelector,
  hasCompatibleAttachmentOptionsSelector,
  hasCompatibleImplementOptionsSelector,
  hasFinancingOptionsSelector,
  hasWarrantyOptionsSelector,
  paymentSelector,
  selectedAttachmentsSelector,
  selectedFinancingSelector,
  selectedImplementsSelector,
  selectedOptionPriceSelector,
  selectedOptionSelector,
  selectedWarrantySelector,
  subtotalSelector,
} from '../../selectors'
import { formModalOpenState, productDetailsState, tabState } from '../../state'

const SummaryPanel = () => {
  const { title } = useRecoilValue(productDetailsState)

  const tab = useRecoilValue(tabState)
  return (
    <StyledContent id={SUMMARY_PANEL} aria-labelledby={SUMMARY_TAB} hidden={tab !== SUMMARY_TAB}>
      <h2 className='mobile-hidden'>Summary</h2>
      <h2 className='desktop-hidden mobile-font-size-h3'>Your {title}</h2>
      <Spacer />
      <MobileView />
      <DesktopView />
      <Spacer size='l' className='mobile-hidden' />
      <Disclaimer className='mobile-hidden' />
    </StyledContent>
  )
}

const StyledContent = styled(Content)`
  .none-selected {
    margin-top: 0;
  }

  .option-grid-section-header {
    h3 {
      display: inline-block;
      margin-right: var(--size-s);
    }

    .edit-button {
      color: var(--color-g400);
      display: inline-block;

      :after {
        content: ' ›';
      }

      :hover,
      :focus {
        text-decoration: underline;
      }
    }
  }

  .cta-buttons {
    a {
      display: inline-block;
      text-decoration: none;
    }
  }

  @media screen and (max-width: 899px) {
    background-color: #fff;
    border-bottom: 1px solid var(--color-n30);

    .summary-grand-total {
      font-size: var(--font-size-h4);
      font-weight: 600;
      margin-bottom: var(--size-xs);
      margin-right: var(--size-s);
    }

    .summary-payment {
      font-size: 1.25rem;
      margin-bottom: var(--size-xs);
    }

    .cta-buttons {
      ${clearfix}

      > * {
        ${column('1/2', 16)}
      }
    }
  }

  @media print {
    display: block !important;
    padding: 0;
    page-break-after: always;
  }
`

const MobileView = () => {
  const { ocaLink } = useRecoilValue(productDetailsState)
  const grandTotal = useRecoilValue(grandTotalSelector)
  const payment = useRecoilValue(paymentSelector)
  const setFormModalOpen = useSetRecoilState(formModalOpenState)
  const selectedFinancing = useRecoilValue(selectedFinancingSelector)
  const subtotal = useRecoilValue(subtotalSelector)

  const openForm = useCallback(() => {
    setFormModalOpen(true)
  }, [setFormModalOpen])

  const clickApplyForFinancing = useCallback(() => {
    gtag('event', gtagEvents.open_credit_application, {
      currency: 'USD',
      value: subtotal,
    })

    fbq('trackCustom', fbqEvents.Lead, {
      currency: 'USD',
      value: subtotal,
    })
  }, [subtotal])
  return (
    <div className='desktop-hidden'>
      <span className='summary-grand-total'>{formatPrice(grandTotal)}</span>
      {isDefined(payment) ? (
        <span className='summary-payment'>
          {formatPrice(payment)}/month
          <Tooltip
            label={`${selectedFinancing.title}${
              isDefined(selectedFinancing.subtitle) ? ` ${selectedFinancing.subtitle}` : ''
            }. ${selectedFinancing.disclaimer}`}
          />
        </span>
      ) : null}
      <Spacer size='xxs' />
      <Disclaimer>
        Taxes, setup, delivery, and fees may not be included. Prices and availability may vary.
      </Disclaimer>
      <Spacer size='m' />
      <div className='cta-buttons'>
        <Button color='yellow' onClick={openForm}>
          Talk to sales
        </Button>
        <Button
          ghost
          as='a'
          href={ocaLink}
          target='_blank'
          rel='noopener noreferrer'
          onClick={clickApplyForFinancing}
        >
          Apply for financing
        </Button>
      </div>
    </div>
  )
}

const DesktopView = () => {
  return (
    <div className='mobile-hidden'>
      <OptionsSection />
      <ImplementsSection />
      <AttachmentsSection />
      <WarrantySection />
      <FinancingSection />
    </div>
  )
}

const OptionsSection = () => {
  const { moreDetailsLink } = useRecoilValue(productDetailsState)
  const selectedOption = useRecoilValue(selectedOptionSelector)
  const selectedOptionPrice = useRecoilValue(selectedOptionPriceSelector)
  const { changeTab } = useTab()

  const hasSelectedOption = isDefined(selectedOption)
  return (
    <OptionListSection>
      <div className='option-grid-section-header'>
        <h3>Options</h3>
        <UnstyledButton
          className='edit-button'
          aria-controls={OPTIONS_PANEL}
          onClick={() => changeTab(OPTIONS_TAB)}
        >
          {hasSelectedOption ? 'Edit' : 'Add'}
        </UnstyledButton>
      </div>
      <Spacer size='s' />
      {hasSelectedOption ? (
        <OptionList>
          <OptionListItem
            compareAtPrice={selectedOption.msrp}
            compareAtPriceTooltip={
              isNumber(selectedOption.msrp) && selectedOption.msrp > selectedOptionPrice
                ? "Manufacturer's Suggested Retail Price (MSRP)"
                : null
            }
            description={selectedOption.buildAndPriceDescriptionHtml}
            images={selectedOption.images}
            moreDetailsLink={moreDetailsLink}
            price={selectedOptionPrice}
            title={selectedOption.title}
          />
        </OptionList>
      ) : (
        <>
          <p className='none-selected'>No option selected.</p>
          <Button aria-controls={OPTIONS_PANEL} onClick={() => changeTab(OPTIONS_TAB)} ghost>
            Choose option
          </Button>
        </>
      )}
    </OptionListSection>
  )
}

const ImplementsSection = () => {
  const hasCompatibleImplementOptions = useRecoilValue(hasCompatibleImplementOptionsSelector)
  const selectedImplements = useRecoilValue(selectedImplementsSelector)
  const { changeTab } = useTab()

  const hasSelectedImplements = Array.isArray(selectedImplements) && selectedImplements.length > 0
  return hasCompatibleImplementOptions ? (
    <OptionListSection>
      <div className='option-grid-section-header'>
        <h3>Implements</h3>
        <UnstyledButton
          className='edit-button'
          aria-controls={IMPLEMENTS_PANEL}
          onClick={() => changeTab(IMPLEMENTS_TAB)}
        >
          {hasSelectedImplements ? 'Edit' : 'Add'}
        </UnstyledButton>
      </div>
      <Spacer size='s' />
      {hasSelectedImplements ? (
        <OptionList>
          {selectedImplements.map(implement => (
            <OptionListItem
              compareAtPrice={implement.compareAtPrice}
              description={implement.buildAndPriceDescriptionHtml}
              images={implement.images}
              moreDetailsLink={implement.link}
              price={implement.price}
              sku={`Part #${implement.sku}`}
              title={implement.title}
              key={implement.id}
            />
          ))}
        </OptionList>
      ) : (
        <>
          <p className='none-selected'>No implements selected.</p>
          <Button aria-controls={IMPLEMENTS_PANEL} onClick={() => changeTab(IMPLEMENTS_TAB)} ghost>
            Add implements
          </Button>
        </>
      )}
    </OptionListSection>
  ) : null
}

const AttachmentsSection = () => {
  const hasCompatibleAttachmentOptions = useRecoilValue(hasCompatibleAttachmentOptionsSelector)
  const selectedAttachments = useRecoilValue(selectedAttachmentsSelector)
  const { changeTab } = useTab()

  const hasSelectedAttachments =
    Array.isArray(selectedAttachments) && selectedAttachments.length > 0
  return hasCompatibleAttachmentOptions ? (
    <OptionListSection>
      <div className='option-grid-section-header'>
        <h3>Attachments</h3>
        <UnstyledButton
          className='edit-button'
          aria-controls={ATTACHMENTS_PANEL}
          onClick={() => changeTab(ATTACHMENTS_TAB)}
        >
          {hasSelectedAttachments ? 'Edit' : 'Add'}
        </UnstyledButton>
      </div>
      <Spacer size='s' />
      {hasSelectedAttachments ? (
        <OptionList>
          {selectedAttachments.map(attachment => (
            <OptionListItem
              compareAtPrice={attachment.compareAtPrice}
              compareAtPriceTooltip={
                isDefined(attachment.discountPromotion)
                  ? `${attachment.discountPromotion.title}${
                      isDefined(attachment.discountPromotion.subtitle)
                        ? ` ${attachment.discountPromotion.subtitle}`
                        : ''
                    }. ${attachment.discountPromotion.disclaimer}`
                  : null
              }
              description={attachment.buildAndPriceDescriptionHtml}
              images={attachment.images}
              moreDetailsLink={attachment.link}
              price={attachment.price}
              sku={`Part #${attachment.sku}`}
              title={attachment.title}
              key={attachment.id}
            />
          ))}
        </OptionList>
      ) : (
        <>
          <p className='none-selected'>No attachments selected.</p>
          <Button
            aria-controls={ATTACHMENTS_PANEL}
            onClick={() => changeTab(ATTACHMENTS_TAB)}
            ghost
          >
            Add attachments
          </Button>
        </>
      )}
    </OptionListSection>
  ) : null
}

const WarrantySection = () => {
  const hasWarrantyOptions = useRecoilValue(hasWarrantyOptionsSelector)
  const selectedOption = useRecoilValue(selectedOptionSelector)
  const selectedWarranty = useRecoilValue(selectedWarrantySelector)
  const { changeTab } = useTab()

  const hasSelectedOption = isDefined(selectedOption)
  const hasSelectedWarranty = isDefined(selectedWarranty)
  return hasWarrantyOptions ? (
    <OptionListSection>
      <div className='option-grid-section-header'>
        <h3>Warranty</h3>
        <UnstyledButton
          className='edit-button'
          aria-controls={WARRANTY_PANEL}
          onClick={() => changeTab(WARRANTY_TAB)}
        >
          Edit
        </UnstyledButton>
      </div>
      <Spacer size='s' />
      {hasSelectedOption && hasSelectedWarranty ? (
        <>
          <OptionList>
            <OptionListItem
              compareAtPrice={selectedWarranty.compareAtPrice}
              compareAtPriceTooltip={
                isDefined(selectedWarranty.discountPromotion)
                  ? `${selectedWarranty.discountPromotion.title}${
                      isDefined(selectedWarranty.discountPromotion.subtitle)
                        ? ` ${selectedWarranty.discountPromotion.subtitle}`
                        : ''
                    }. ${selectedWarranty.discountPromotion.disclaimer}`
                  : null
              }
              description={selectedWarranty.buildAndPriceDescriptionHtml}
              moreDetailsLink={selectedWarranty.type.link}
              price={selectedWarranty.price}
              showImage={false}
              title={formatWarranty(selectedWarranty)}
            />
          </OptionList>
          {selectedWarranty.isFactory ? (
            <>
              <Spacer size='s' />
              <Button aria-controls={WARRANTY_PANEL} onClick={() => changeTab(WARRANTY_TAB)} ghost>
                Add extended warranty
              </Button>
            </>
          ) : null}
        </>
      ) : (
        <>
          <p className='none-selected'>No option selected.</p>
          <Button aria-controls={OPTIONS_PANEL} onClick={() => changeTab(OPTIONS_TAB)} ghost>
            Choose option
          </Button>
        </>
      )}
    </OptionListSection>
  ) : null
}

const FinancingSection = () => {
  const hasFinancingOptions = useRecoilValue(hasFinancingOptionsSelector)
  const selectedFinancing = useRecoilValue(selectedFinancingSelector)
  const { financing } = useFinancing()
  const { changeTab } = useTab()
  return hasFinancingOptions ? (
    <OptionListSection>
      <div className='option-grid-section-header'>
        <h3>Financing</h3>
        <UnstyledButton
          className='edit-button'
          aria-controls={FINANCING_PANEL}
          onClick={() => changeTab(FINANCING_TAB)}
        >
          {FINANCING_PROMOTION_DEFAULT === financing ? 'Add' : 'Edit'}
        </UnstyledButton>
      </div>
      <Spacer size='s' />
      {FINANCING_PROMOTION_DEFAULT !== financing ? (
        <OptionList>
          <OptionListItem
            showImage={false}
            title={selectedFinancing.title}
            tooltip={selectedFinancing.disclaimer}
          />
        </OptionList>
      ) : (
        <>
          <p className='none-selected'>No financing selected.</p>
          <Button aria-controls={FINANCING_PANEL} onClick={() => changeTab(FINANCING_TAB)} ghost>
            Choose financing
          </Button>
        </>
      )}
    </OptionListSection>
  ) : null
}

export default SummaryPanel
