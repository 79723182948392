import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { formModalOpenState } from '../state'

const useFormModal = () => {
  const [formModalOpen, setFormModalOpen] = useRecoilState(formModalOpenState)

  const toggleFormModalOpen = useCallback(
    nextValue => {
      if (typeof nextValue === 'boolean') {
        setFormModalOpen(nextValue)
      } else {
        setFormModalOpen(currentValue => !currentValue)
      }
    },
    [setFormModalOpen]
  )

  return [formModalOpen, toggleFormModalOpen]
}

export default useFormModal
