import React, { memo } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import Button from 'components/button'
import UnstyledButton from 'components/unstyled-button'
import Checkbox, {
  checkboxInputContainerClassName,
  styledCheckboxInputClassName,
} from 'components/checkbox'
import Radio, { radioInputContainerClassName, styledRadioInputClassName } from 'components/radio'
import NoImage from 'components/no-image'
import { formatPrice, isDefined, isNumber } from 'utils'
import { clearfix, column } from 'styles'
import { Tooltip } from 'components/tooltip'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const getInputComponent = type => {
  switch (type) {
    case 'radio':
      return Radio
    case 'checkbox':
      return Checkbox
    default:
      return React.Fragment
  }
}

const OptionListItem = ({
  className = '',
  compareAtPrice,
  compareAtPriceTooltip,
  description,
  images,
  inputChangeHandler,
  inputChangeValue,
  inputChecked,
  inputId,
  inputName,
  inputType,
  moreDetailsLink,
  price,
  showImage = true,
  sku,
  title,
  tooltip,
  ...props
}) => {
  const image =
    Array.isArray(images) && images.length > 0 ? (
      <Img
        fluid={{ ...images[0].asset.fluid, aspectRatio: 8 / 5 }}
        objectFit='contain'
        alt={title}
      />
    ) : (
      <NoImage />
    )
  const innerContent = (
    <>
      {showImage ? (
        <div className={`option-image ${isDefined(inputType) ? 'mobile-hidden' : ''}`}>{image}</div>
      ) : null}
      <div className='option-content'>
        <div className='option-content-top'>
          <p className='option-title'>
            {title}
            {isDefined(tooltip) && tooltip !== '' ? <Tooltip label={tooltip} /> : null}
          </p>
          <div className='option-price-container'>
            {isNumber(price) ? (
              <span className='option-price desktop-hidden'>
                {price === 0 ? 'Included' : formatPrice(price)}
              </span>
            ) : null}
            {isNumber(compareAtPrice) && compareAtPrice > price ? (
              <span className='option-compare-at-price desktop-hidden'>
                {formatPrice(compareAtPrice)}
                {isDefined(compareAtPriceTooltip) && compareAtPriceTooltip !== '' ? (
                  <Tooltip label={compareAtPriceTooltip} />
                ) : null}
              </span>
            ) : null}
          </div>
        </div>
        {isDefined(description) ? (
          <div className='option-description' dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        {isDefined(sku) ? <p className='option-sku'>{sku}</p> : null}
      </div>
    </>
  )
  const Input = isDefined(inputType) ? getInputComponent(inputType) : null
  return (
    <StyledOptionListItem
      className={`${isDefined(inputType) && inputChecked ? 'selected' : ''} ${className}`}
      {...props}
    >
      {isDefined(inputType) ? (
        <>
          <UnstyledButton onClick={() => inputChangeHandler(inputChangeValue)}>
            {showImage ? <div className='option-image desktop-hidden'>{image}</div> : null}
            <Input
              checked={inputChecked}
              onChange={() => inputChangeHandler(inputChangeValue)}
              name={inputName}
              value={inputChangeValue}
              id={inputId}
              tabIndex='-1'
            />
            {innerContent}
          </UnstyledButton>
          <div className='option-buttons-mobile desktop-hidden'>
            <Button color='green' onClick={() => inputChangeHandler(inputChangeValue)}>
              {inputChecked ? 'Selected' : 'Select'}
            </Button>
            {isDefined(moreDetailsLink) ? (
              <Button
                as='a'
                href={moreDetailsLink}
                target='_blank'
                rel='noopener noreferrer'
                color='gray'
                ghost
              >
                More details
              </Button>
            ) : null}
          </div>
        </>
      ) : (
        innerContent
      )}
      <div className='option-end mobile-hidden'>
        {isNumber(price) ? (
          <span className='option-price'>{price === 0 ? 'Included' : formatPrice(price)}</span>
        ) : null}
        {isNumber(compareAtPrice) && compareAtPrice > price ? (
          <span className='option-compare-at-price'>
            {formatPrice(compareAtPrice)}
            {isDefined(compareAtPriceTooltip) ? <Tooltip label={compareAtPriceTooltip} /> : null}
          </span>
        ) : null}
        {isDefined(moreDetailsLink) ? (
          <OutboundLink className='option-more-details' href={moreDetailsLink} target='_blank'>
            More details ›
          </OutboundLink>
        ) : null}
      </div>
    </StyledOptionListItem>
  )
}

const StyledOptionListItem = styled.li`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: var(--size-s);

  @media (min-width: 900px) {
    border-top: 1px solid var(--color-n30);

    :last-child {
      border-bottom: 1px solid var(--color-n30);
    }
  }

  button {
    display: flex;
    flex-grow: 2;

    :hover,
    :focus {
      outline: none;

      .option-title {
        text-decoration: underline;
      }

      .${styledCheckboxInputClassName}, .${styledRadioInputClassName} {
        outline: 1px solid Highlight;

        @media (-webkit-min-device-pixel-ratio: 0) {
          outline: 1px auto -webkit-focus-ring-color;
        }
      }
    }

    .option-image {
      margin-left: var(--size-s);
      margin-right: 0;
    }

    .option-content {
      margin-left: var(--size-m);
    }

    .${checkboxInputContainerClassName}, .${radioInputContainerClassName} {
      align-self: center;
    }
  }

  .option-image {
    align-self: flex-start;
    flex-shrink: 1;
    height: auto;
    margin-right: var(--size-m);
    max-width: 150px;
    min-width: 100px;
    width: 40%;
  }

  .option-content {
    align-self: center;
    flex-grow: 2;
    text-align: left;
  }

  .option-title {
    font-weight: 700;
    margin: 0 0 var(--size-xxs);

    svg {
      font-size: 1rem;
    }
  }

  .option-price-container {
    flex-shrink: 0;
  }

  .option-description {
    margin: 0 0 var(--size-xs);

    p {
      margin: 0;
    }

    ul {
      list-style: disc;

      li {
        margin: 0 0 var(--size-xxs);

        :last-child {
          margin: 0;
        }
      }
    }
  }

  .option-sku {
    color: var(--color-n600);
    font-size: 0.75rem;
    margin: var(--size-xxs) 0 0;
  }

  .option-end {
    align-self: center;
    margin-left: var(--size-m);
    text-align: right;
  }

  .option-price {
    display: block;
    font-weight: 600;
    line-height: 1;
    width: 100px;
  }

  .option-compare-at-price {
    color: var(--color-n400);
    display: block;
    font-size: 0.875rem;
    line-height: 1;
    margin-top: var(--size-xxs);
    text-decoration: line-through;
    width: 100px;
  }

  .option-more-details {
    color: var(--color-g400);
    display: inline-block;
    font-size: 0.875rem;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 899px) {
    border: 1px solid var(--color-n30);
    display: block;

    &.selected {
      border: 1px solid var(--color-g400);
    }

    :not(:last-child) {
      margin-bottom: var(--size-s);
    }

    button {
      display: block;
      width: 100%;

      .option-image {
        margin-left: 0;
      }

      .option-content {
        display: inline-block;
        margin-left: 12px;
        width: calc(100% - 20px - 12px);
      }

      .${checkboxInputContainerClassName}, .${radioInputContainerClassName} {
        vertical-align: top;
      }
    }

    .option-image {
      margin-bottom: var(--size-s);
      margin-right: 0;
      max-width: none;
      min-width: 0;
      width: 100%;
    }

    .option-content-top {
      display: flex;
      justify-content: space-between;
    }

    .option-price {
      font-size: 1.125rem;
      line-height: 1.35;
      margin-left: var(--size-s);
      text-align: right;
      width: auto;
    }

    .option-compare-at-price {
      font-size: 1rem;
      margin-left: var(--size-s);
      text-align: right;
      width: auto;
    }

    .option-buttons-mobile {
      ${clearfix}
      margin-top: var(--size-s);

      > * {
        ${column('1/2', 16)}
      }

      a {
        text-decoration: none;
      }
    }
  }

  @media print {
    page-break-inside: avoid;
  }
`

export default memo(OptionListItem)
