import { selector } from 'recoil'
import { isDefined } from 'utils'
import { selectedWarrantySelector } from '.'

const SELECTED_WARRANTY_PRICE_SELECTOR = 'SELECTED_WARRANTY_PRICE_SELECTOR'

const selectedWarrantyPriceSelector = selector({
  key: SELECTED_WARRANTY_PRICE_SELECTOR,
  get: ({ get }) => {
    const selectedWarranty = get(selectedWarrantySelector)

    if (isDefined(selectedWarranty)) {
      return selectedWarranty.price
    }

    return 0
  },
})

export default selectedWarrantyPriceSelector
