import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components/button'

const Submit = ({ disabled, value, ...props }) => {
  return (
    <Button
      as={StyledInput}
      type='submit'
      value={value}
      disabled={disabled}
      color='yellow'
      {...props}
    />
  )
}

Submit.propTypes = {
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
}

Submit.defaultProps = {
  disabled: false,
  value: 'Submit',
}

const StyledInput = styled.input`
  min-width: 100px;
`

export default Submit
