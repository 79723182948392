import { atom } from 'recoil'

export const ATTACHMENT_PICK_X_GET_Y_DISCOUNT_PROMOTIONS_STATE =
  'ATTACHMENT_PICK_X_GET_Y_DISCOUNT_PROMOTIONS_STATE'

const attachmentPickXGetYDiscountPromotionsState = atom({
  key: ATTACHMENT_PICK_X_GET_Y_DISCOUNT_PROMOTIONS_STATE,
  default: [],
})

export default attachmentPickXGetYDiscountPromotionsState
