import { useCallback } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { panelsRefState, tabState } from '../state'
import { TABS } from '../constants'
import { gtag } from '@hutson/utils'

const useTab = () => {
  const [tab, setTab] = useRecoilState(tabState)
  const panelsRef = useRecoilValue(panelsRefState)

  const changeTab = useCallback(
    val => {
      // Update tab state
      setTab(val)

      // Send Google Analytics event
      for (let i = 0; i < TABS.length; i++) {
        if (TABS[i].tab === val) {
          gtag('event', 'screen_view', { screen_name: TABS[i].name })
          break
        }
      }

      // Focus on panel element
      panelsRef.focus()
    },
    [setTab, panelsRef]
  )

  return {
    changeTab,
    tab,
  }
}

export default useTab
