import { useCallback, useEffect, useRef } from 'react'

const useIsMounted = () => {
  const mountedRef = useRef(false)
  const get = useCallback(() => {
    return mountedRef.current
  }, [])

  useEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false
    }
  })

  return get
}

export default useIsMounted
