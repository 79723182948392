import { atom } from 'recoil'

export const COMPATIBLE_IMPLEMENTS_CATEGORIES_STATE = 'COMPATIBLE_IMPLEMENTS_CATEGORIES_STATE'

const compatibleImplementsCategoriesState = atom({
  key: COMPATIBLE_IMPLEMENTS_CATEGORIES_STATE,
  default: [],
})

export default compatibleImplementsCategoriesState
