import { selector } from 'recoil'
import {
  dgbSasaDiscountSelector,
  selectedOptionMsrpDiscountSelector,
  selectedOptionPriceAdjustmentsSelector,
  selectedOptionSelector,
  totalOptionDiscountsSelector,
} from '../'
import { isDefined, sum } from 'utils'

const HUTSON_SAVINGS_SELECTOR = 'HUTSON_SAVINGS_SELECTOR'

/**
 * Total Hutson savings (positive). Includes difference between MSRP and cost (or price if not using margin
 * calculation), price adjustments from margin changes, DGB, and SASA.
 */
const hutsonSavingsSelector = selector({
  key: HUTSON_SAVINGS_SELECTOR,
  get: ({ get }) => {
    const selectedOption = get(selectedOptionSelector)
    const selectedOptionMsrpDiscount = get(selectedOptionMsrpDiscountSelector)
    const selectedOptionPriceAdjustments = get(selectedOptionPriceAdjustmentsSelector)
    const dgbSasaDiscount = get(dgbSasaDiscountSelector)
    const totalOptionDiscounts = get(totalOptionDiscountsSelector)

    if (!isDefined(selectedOption)) {
      return 0
    }

    if (isDefined(selectedOption.advertisedPrice)) {
      return [selectedOptionMsrpDiscount, -totalOptionDiscounts].reduce(sum)
    }

    return [
      selectedOptionMsrpDiscount,
      -selectedOption.marginAmount,
      selectedOptionPriceAdjustments,
      dgbSasaDiscount,
    ].reduce(sum)
  },
})

export default hutsonSavingsSelector
