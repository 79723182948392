import { atom } from 'recoil'

export const OPTION_STATE = 'OPTION_STATE'

const optionState = atom({
  key: OPTION_STATE,
  default: null,
})

export default optionState
