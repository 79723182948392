import React from 'react'
import styled from 'styled-components'
import UnstyledButton from 'components/unstyled-button'

const SidebarSectionHeader = ({
  children,
  buttonClickHandler,
  buttonControls,
  buttonText,
  buttonValue,
  showButton,
  ...props
}) => {
  return (
    <StyledSidebarSectionHeader {...props}>
      <h3>{children}</h3>
      {showButton ? (
        <UnstyledButton
          className='edit-button'
          aria-controls={buttonControls}
          onClick={() => buttonClickHandler(buttonValue)}
        >
          {buttonText}
        </UnstyledButton>
      ) : null}
    </StyledSidebarSectionHeader>
  )
}

const StyledSidebarSectionHeader = styled.div`
  h3 {
    display: inline-block;
    font-size: var(--font-size-h4);
    margin-right: var(--size-s);
  }

  .edit-button {
    color: var(--color-g400);
    display: inline-block;

    :after {
      content: ' ›';
    }

    :hover,
    :focus {
      text-decoration: underline;
    }
  }
`

export default SidebarSectionHeader
