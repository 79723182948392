import { selector } from 'recoil'
import { dgbPromotionsState, sasaPromotionsState } from '../../state'
import { sum } from 'utils'

const DGB_SASA_DISCOUNT_SELECTOR = 'DGB_SASA_DISCOUNT_SELECTOR'

/**
 * Total amount of DGA and SASA discounts (positive).
 */
const dgbSasaDiscountSelector = selector({
  key: DGB_SASA_DISCOUNT_SELECTOR,
  get: ({ get }) => {
    const dgbPromotions = get(dgbPromotionsState)
    const sasaPromotions = get(sasaPromotionsState)

    if (dgbPromotions.length === 0 && sasaPromotionsState.length === 0) {
      return 0
    }

    return [
      ...dgbPromotions.map(promo => promo.details.dgb),
      ...sasaPromotions.map(promo => promo.details.sasa),
    ].reduce(sum, 0)
  },
})

export default dgbSasaDiscountSelector
