import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Label from './label'
import InputBase from './input-base'

const Input = ({ id, label, name, register, required, ...props }) => {
  return (
    <>
      <Label htmlFor={id} required={required}>
        {label}
      </Label>
      <StyledInputBase
        ref={register({ required })}
        name={name}
        required={required}
        id={id}
        {...props}
      />
    </>
  )
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
}

Input.defaultProps = {
  required: false,
}

const StyledInputBase = styled(InputBase)`
  margin-top: var(--size-xs);
`

export default Input
