import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

export const styledRadioInputClassName = 'styled-radio-input'
export const radioInputContainerClassName = 'checkbox-input-container'

const Radio = ({ checked, className, disabled, id, name, required, ...props }) => {
  return (
    <StyledRadio className={radioInputContainerClassName}>
      <input
        checked={checked}
        className={`visually-hidden ${className}`}
        disabled={disabled}
        id={id}
        name={name}
        required={required}
        type='radio'
        {...props}
      />
      <div className={styledRadioInputClassName} />
    </StyledRadio>
  )
}

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
}

Radio.defaultProps = {
  className: '',
  required: false,
}

const StyledRadio = styled.div`
  display: inline-block;

  input {
    :disabled {
      ~ .${styledRadioInputClassName} {
        border: 1px solid var(--color-n30);
      }
    }

    :not(:disabled) {
      ~ .${styledRadioInputClassName} {
        cursor: pointer;
      }

      :checked {
        ~ .${styledRadioInputClassName} {
          background-color: var(--color-g400);
          border-color: var(--color-g400);

          :after {
            display: block;
          }
        }
      }

      :focus,
      :hover {
        ~ .${styledRadioInputClassName} {
          outline: 1px solid Highlight;

          @media (-webkit-min-device-pixel-ratio: 0) {
            outline: 1px auto -webkit-focus-ring-color;
          }
        }
      }
    }
  }

  .${styledRadioInputClassName} {
    background-color: #fff;
    border: 1px solid var(--color-n50);
    border-radius: 50%;
    display: inline-block;
    height: 20px;
    position: relative;
    vertical-align: middle;
    width: 20px;

    :after {
      border: solid #fff;
      border-width: 0 2px 2px 0;
      content: '';
      display: none;
      height: 12px;
      left: 6px;
      position: absolute;
      top: 2px;
      transform: rotate(45deg);
      width: 6px;
    }
  }
`

export default Radio
