import { atom } from 'recoil'

export const FORM_MODAL_OPEN_STATE = 'FORM_MODAL_OPEN_STATE'

const formModalOpenState = atom({
  key: FORM_MODAL_OPEN_STATE,
  default: false,
})

export default formModalOpenState
