import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'
import Content from 'components/content'
import Disclaimer from 'components/disclaimer'
import OptionList from 'components/option-list/option-list'
import OptionListItem from 'components/option-list/option-list-item'
import OptionListSection from 'components/option-list/option-list-section'
import Spacer from 'components/spacer'
import Button from 'components/button'
import { isDefined } from 'utils'
import { IMPLEMENTS_PANEL, IMPLEMENTS_TAB } from '../../constants'
import { useImplements, useTab } from '../../hooks'
import { enabledTabsSelector, hasCompatibleImplementOptionsSelector } from '../../selectors'
import { compatibleImplementsCategoriesState } from '../../state'

const ImplementsPanel = () => {
  const compatibleImplementsCategories = useRecoilValue(compatibleImplementsCategoriesState)
  const { changeImplement, implementIds } = useImplements()
  const { tab, changeTab } = useTab()
  const [nextTab, setNextTab] = useState()
  const enabledTabs = useRecoilValue(enabledTabsSelector)

  const hasSelectedImplements = Array.isArray(implementIds) && implementIds.length > 0

  useEffect(() => {
    if (isDefined(enabledTabs)) {
      const currentTabIndex = enabledTabs.findIndex(obj => obj.tab === IMPLEMENTS_TAB)
      setNextTab(enabledTabs[currentTabIndex + 1])
    }
  }, [enabledTabs])
  return (
    <StyledContent
      id={IMPLEMENTS_PANEL}
      aria-labelledby={IMPLEMENTS_TAB}
      hidden={tab !== IMPLEMENTS_TAB}
    >
      <h2 className='mobile-hidden'>Implements</h2>
      <Spacer className='mobile-hidden' />
      <div>
        {compatibleImplementsCategories.map(category => (
          <OptionListSection key={category.title}>
            <h3>{category.title}</h3>
            <Spacer size='s' />
            <OptionList>
              {category.implements.map(implement => (
                <OptionListItem
                  compareAtPrice={implement.compareAtPrice}
                  description={implement.buildAndPriceDescriptionHtml}
                  images={implement.images}
                  inputChangeHandler={changeImplement}
                  inputChangeValue={implement.id}
                  inputChecked={hasSelectedImplements ? implementIds.includes(implement.id) : false}
                  inputId={`checkbox-implements-${implement.id}`}
                  inputName='implement'
                  inputType='checkbox'
                  moreDetailsLink={implement.link}
                  price={implement.price}
                  sku={`Part #${implement.sku}`}
                  title={implement.title}
                  key={implement.id}
                />
              ))}
            </OptionList>
          </OptionListSection>
        ))}
      </div>
      <Spacer />
      {isDefined(nextTab) ? (
        <div className='next-button-container'>
          <Button aria-controls={nextTab.panel} onClick={() => changeTab(nextTab.tab)} ghost>
            Next: {nextTab.name}
          </Button>
        </div>
      ) : null}
      <Spacer size='l' />
      <Disclaimer />
    </StyledContent>
  )
}

const StyledContent = styled(Content)`
  .next-button-container {
    text-align: right;

    button {
      display: inline-block;
    }
  }
`

export default props => {
  const hasCompatibleImplementOptions = useRecoilValue(hasCompatibleImplementOptionsSelector)
  return hasCompatibleImplementOptions ? <ImplementsPanel {...props} /> : null
}
