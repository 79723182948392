import React from 'react'
import styled from 'styled-components'

const OptionListSection = ({ children, ...props }) => {
  return <StyledOptionListSection {...props}>{children}</StyledOptionListSection>
}

const StyledOptionListSection = styled.div`
  :not(:last-child) {
    margin-bottom: var(--size-xl);
  }
`

export default OptionListSection
