import { selector } from 'recoil'
import { isDefined } from 'utils'
import { selectedOptionSelector } from '.'

const SELECTED_OPTION_PRICE_SELECTOR = 'SELECTED_OPTION_PRICE_SELECTOR'

const selectedOptionPriceSelector = selector({
  key: SELECTED_OPTION_PRICE_SELECTOR,
  get: ({ get }) => {
    const selectedOption = get(selectedOptionSelector)

    if (!isDefined(selectedOption)) {
      return 0
    }

    if (isDefined(selectedOption.advertisedPrice)) {
      return selectedOption.advertisedPrice
    }

    return selectedOption.price
  },
})

export default selectedOptionPriceSelector
