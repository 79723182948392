import { atom } from 'recoil'

export const COMPATIBLE_IMPLEMENTS_STATE = 'COMPATIBLE_IMPLEMENTS_STATE'

const compatibleImplementsState = atom({
  key: COMPATIBLE_IMPLEMENTS_STATE,
  default: [],
})

export default compatibleImplementsState
