import React from 'react'
import styled from 'styled-components'

const UnstyledButton = props => {
  return <Button {...props} />
}

const Button = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 1.125em;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: center;
  text-transform: none;
  width: auto;
`

export default UnstyledButton
