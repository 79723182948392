import { selector } from 'recoil'
import { warrantyOptionsState } from '../state'

const HAS_WARRANTY_OPTIONS_SELECTOR = 'HAS_WARRANTY_OPTIONS_SELECTOR'

const hasWarrantyOptionsSelector = selector({
  key: HAS_WARRANTY_OPTIONS_SELECTOR,
  get: ({ get }) => {
    const warrantyOptions = get(warrantyOptionsState)
    return Array.isArray(warrantyOptions) && warrantyOptions.length > 0
  },
})

export default hasWarrantyOptionsSelector
