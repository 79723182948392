import { selector } from 'recoil'
import { warrantyState, warrantyOptionsState } from '../state'

const SELECTED_WARRANTY_SELECTOR = 'SELECTED_WARRANTY_SELECTOR'

const selectedWarrantySelector = selector({
  key: SELECTED_WARRANTY_SELECTOR,
  get: ({ get }) => {
    const warranty = get(warrantyState)
    const warrantyOptions = get(warrantyOptionsState)

    return warrantyOptions.find(obj => obj._key === warranty)
  },
})

export default selectedWarrantySelector
