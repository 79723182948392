import { atom } from 'recoil'

export const TWO_OR_MORE_IMPLEMENT_PROMOTIONS_STATE = 'TWO_OR_MORE_IMPLEMENT_PROMOTIONS_STATE'

const twoOrMoreImplementPromotionsState = atom({
  key: TWO_OR_MORE_IMPLEMENT_PROMOTIONS_STATE,
  default: [],
})

export default twoOrMoreImplementPromotionsState
