import React from 'react'
import styled from 'styled-components'

const SidebarSubsection = ({ children, ...props }) => {
  return <StyledSidebarSubsection {...props}>{children}</StyledSidebarSubsection>
}

const StyledSidebarSubsection = styled.div`
  :not(:first-child) {
    border-top: 1px dashed var(--color-n30);
  }
`

export default SidebarSubsection
