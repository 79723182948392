import { atom } from 'recoil'

export const SASA_PROMOTIONS_STATE = 'SASA_PROMOTIONS_STATE'

const sasaPromotionsState = atom({
  key: SASA_PROMOTIONS_STATE,
  default: [],
})

export default sasaPromotionsState
