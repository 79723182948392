import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { implementIdsState } from '../state'

const useImplements = () => {
  const [implementIds, setImplementIds] = useRecoilState(implementIdsState)

  const changeImplement = useCallback(
    val => {
      setImplementIds(prevState => {
        // Check if implement is already selected
        const matchIndex = Array.isArray(prevState) ? prevState.findIndex(id => id === val) : -1

        // If no match found, add implement
        if (matchIndex === -1) {
          if (Array.isArray(prevState)) {
            return [...prevState, val]
          }
          return [val]
        }

        // Otherwise, remove the implement
        const newState = [...prevState]
        newState.splice(matchIndex, 1)
        if (newState.length === 0) {
          return null
        }
        return newState
      })
    },
    [setImplementIds]
  )

  return {
    changeImplement,
    implementIds,
  }
}

export default useImplements
