import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Label from './label'
import InputBase from './input-base'
import { isValidEmail } from 'utils'

const Email = ({ id, label, name, register, required, ...props }) => {
  return (
    <>
      <Label htmlFor={id} required={required}>
        {label}
      </Label>
      <StyledInputBase
        type='email'
        ref={register({ required, validate: value => isValidEmail(value) })}
        name={name}
        required={required}
        id={id}
        {...props}
      />
    </>
  )
}

Email.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
}

Email.defaultProps = {
  id: 'email',
  label: 'Email',
  name: 'Email',
  required: false,
}

const StyledInputBase = styled(InputBase)`
  margin-top: var(--size-xs);
`

export default Email
