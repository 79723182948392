import { atom } from 'recoil'

export const CASH_PURCHASE_DISCOUNT_PROMOTIONS_STATE = 'CASH_PURCHASE_DISCOUNT_PROMOTIONS_STATE'

const cashPurchaseDiscountPromotionsState = atom({
  key: CASH_PURCHASE_DISCOUNT_PROMOTIONS_STATE,
  default: [],
})

export default cashPurchaseDiscountPromotionsState
