import React, { useState } from 'react'
import styled from 'styled-components'
import Form from 'react-netlify-form'
import { useForm } from 'react-hook-form'
import { Email, FieldError, Input, Location, Phone, Submit } from 'components/forms/elements'
import Spacer from 'components/spacer'
import { clearfix, column } from 'styles'
import { useIsMounted } from 'hooks'
import { fbq, fbqEvents, gtag, gtagEvents } from '@hutson/utils'
import formDataEntries from 'form-data-entries'
import fetch from 'isomorphic-fetch'

const TalkToSalesForm = ({
  buildDetails,
  categoryCode,
  name = 'Talk to Sales',
  pageURL,
  productName,
  productPrice,
  productType,
  subcategoryCode,
}) => {
  const {
    register,
    errors,
    formState: { isDirty, isValid },
    control,
  } = useForm({ mode: 'onBlur' })

  const [responseData, setResponseData] = useState({
    error: false,
    success: false,
  })
  const isMounted = useIsMounted()

  const handleSubmit = () => {
    if (!isValid && isDirty) {
      return
    }
    const formData = {}
    for (const [key, value] of formDataEntries(document.forms[name])) {
      formData[key] = value
    }
    formData['pageName'] = document.title

    fetch('/.netlify/functions/talk-to-sales-form-handler', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(formData),
    })
      .then(res => {
        if (isMounted()) {
          if (res.status !== 200) {
            setResponseData({
              error: true,
              success: false,
            })
          } else {
            setResponseData({
              error: false,
              success: true,
            })
          }
        }
      })
      .catch(() => {
        if (isMounted()) {
          setResponseData({
            error: true,
            success: false,
          })
        }
      })

    gtag('event', gtagEvents.generate_lead, {
      currency: 'USD',
      value: productPrice,
      equipment_condition: 'new',
    })

    fbq('track', fbqEvents.Lead, {
      currency: 'USD',
      value: productPrice,
    })
  }
  return (
    <Form name={name} onSubmit={handleSubmit}>
      {({ error, success }) => (
        <div>
          <h3>{name}</h3>
          <Spacer size='l' variable />
          {(success || responseData.success) && (
            <>
              <h4>Success!</h4>
              <p>Your form has been submitted. Our team will be in touch with you soon!</p>
            </>
          )}
          {!(success || responseData.success) && (
            <div>
              <h4>Contact Information</h4>
              <Grid>
                <Column columns='1/2'>
                  <Input
                    label='First Name'
                    name='First Name'
                    id='first-name'
                    register={register}
                    required
                  />
                  {errors['First Name'] ? <FieldError type={errors['First Name'].type} /> : null}
                </Column>
                <Column columns='1/2'>
                  <Input
                    label='Last Name'
                    name='Last Name'
                    id='last-name'
                    register={register}
                    required
                  />
                  {errors['Last Name'] ? <FieldError type={errors['Last Name'].type} /> : null}
                </Column>
              </Grid>
              <Grid>
                <Column columns='2/3'>
                  <Email register={register} required />
                  {errors['Email'] ? <FieldError type={errors['Email'].type} /> : null}
                </Column>
                <Column columns='1/3'>
                  <Phone control={control} required />
                  {errors['Phone'] ? <FieldError type={errors['Phone'].type} /> : null}
                </Column>
              </Grid>
              <Spacer size='l' variable />
              <h4>Preferred Hutson Location</h4>
              <Grid>
                <Column columns='5/5'>
                  <Location register={register} label='Preferred Hutson location' />
                  {errors['Nearest Hutson Location'] ? (
                    <FieldError type={errors['Nearest Hutson Location'].type} />
                  ) : null}
                </Column>
              </Grid>
              <div hidden>
                <label>
                  Build Details
                  <textarea name='Build Details' value={buildDetails} readOnly />
                </label>
                <label>
                  Product Name
                  <input type='text' name='Product Name' value={productName} readOnly />
                </label>
                <label>
                  Product Price
                  <input type='text' name='Product Price' value={productPrice} readOnly />
                </label>
                <label>
                  Product Type
                  <input type='text' name='Product Type' value={productType} readOnly />
                </label>
                <label>
                  Page URL
                  <input type='text' name='Page URL' value={pageURL} readOnly />
                </label>
                <label>
                  Category Code
                  <input type='text' name='Category Code' value={categoryCode} readOnly />
                </label>
                <label>
                  Subcategory Code
                  <input type='text' name='Subcategory Code' value={subcategoryCode} readOnly />
                </label>
              </div>
              <Spacer size='m' />
              <Submit disabled={(!isValid && isDirty) || success} />
              {error && responseData.error && (
                <p>
                  There was a problem processing your form. Please try again. If problems persist,
                  please contact our support team at{' '}
                  <a href='mailto:marketing@hutsoninc.com'>marketing@hutsoninc.com</a>.
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </Form>
  )
}

const Grid = styled.div`
  @media (min-width: 600px) {
    ${clearfix}
  }
`

const Column = styled.div`
  @media (min-width: 600px) {
    ${props => column(props.columns)}
  }
`

export default TalkToSalesForm
