import { atom } from 'recoil'

export const PRODUCT_DETAILS_STATE = 'PRODUCT_DETAILS_STATE'

const productDetailsState = atom({
  key: PRODUCT_DETAILS_STATE,
  default: {},
})

export default productDetailsState
