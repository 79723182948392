import React from 'react'
import styled from 'styled-components'

const SidebarSection = ({ children, ...props }) => {
  return <StyledSidebarSection {...props}>{children}</StyledSidebarSection>
}

const StyledSidebarSection = styled.div`
  padding: var(--size-m) 0;

  :not(:last-child) {
    border-bottom: 1px solid var(--color-n30);
  }

  @media screen and (max-width: 899px) {
    padding: var(--size-s) 0;
  }
`

export default SidebarSection
