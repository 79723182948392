import { atom } from 'recoil'

export const IMPLEMENT_IDS_STATE = 'IMPLEMENT_IDS_STATE'

const implementIdsState = atom({
  key: IMPLEMENT_IDS_STATE,
  default: [],
})

export default implementIdsState
