import { selector } from 'recoil'
import { attachmentsState, compatibleAttachmentsState } from '../state'

const SELECTED_ATTACHMENTS_SELECTOR = 'SELECTED_ATTACHMENTS_SELECTOR'

const selectedAttachmentsSelector = selector({
  key: SELECTED_ATTACHMENTS_SELECTOR,
  get: ({ get }) => {
    const attachments = get(attachmentsState)
    const compatibleAttachments = get(compatibleAttachmentsState)

    if (Array.isArray(attachments) && Array.isArray(compatibleAttachments)) {
      return compatibleAttachments.filter(attachment => {
        return attachments.find(val => val === attachment.id)
      })
    }

    return null
  },
})

export default selectedAttachmentsSelector
