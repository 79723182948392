/**
 * Tabs and panels
 */
export const OPTIONS = 'Options'
export const OPTIONS_TAB = 'options-tab'
export const OPTIONS_PANEL = 'options-panel'

export const IMPLEMENTS = 'Implements'
export const IMPLEMENTS_TAB = 'implements-tab'
export const IMPLEMENTS_PANEL = 'implements-panel'

export const ATTACHMENTS = 'Attachments'
export const ATTACHMENTS_TAB = 'attachments-tab'
export const ATTACHMENTS_PANEL = 'attachments-panel'

export const FINANCING = 'Financing'
export const FINANCING_TAB = 'financing-tab'
export const FINANCING_PANEL = 'financing-panel'

export const WARRANTY = 'Warranty'
export const WARRANTY_TAB = 'warranty-tab'
export const WARRANTY_PANEL = 'warranty-panel'

export const SUMMARY = 'Summary'
export const SUMMARY_TAB = 'summary-tab'
export const SUMMARY_PANEL = 'summary-panel'

export const TABS = [
  { tab: OPTIONS_TAB, panel: OPTIONS_PANEL, name: OPTIONS },
  { tab: IMPLEMENTS_TAB, panel: IMPLEMENTS_PANEL, name: IMPLEMENTS },
  { tab: ATTACHMENTS_TAB, panel: ATTACHMENTS_PANEL, name: ATTACHMENTS },
  { tab: WARRANTY_TAB, panel: WARRANTY_PANEL, name: WARRANTY },
  { tab: FINANCING_TAB, panel: FINANCING_PANEL, name: FINANCING },
  { tab: SUMMARY_TAB, panel: SUMMARY_PANEL, name: SUMMARY },
]

/**
 * Promotions
 */
export const CASH_PURCHASE_DISCOUNT_PROMOTION = 'cash-purchase-discount'
export const DGB_PROMOTION = 'dgb'
export const DISCOUNT_PROMOTION = 'discount'
export const FINANCING_DISCOUNT_PROMOTION = 'financing-discount'
export const FINANCING_PROMOTION = 'financing'
export const PERCENT_DISCOUNT_PROMOTION = 'percent-discount'
export const PICK_X_GET_Y_PERCENT_DISCOUNT_PROMOTION = 'pick-x-get-y-percent-discount'
export const SASA_PROMOTION = 'sasa'
export const TWO_OR_MORE_IMPLMENTS_PROMOTION = 'two-or-more-implements'
export const WARRANTY_PERCENT_DISCOUNT_PROMOTION = 'warranty-percent-discount'

export const PROMOTION_TYPES = [
  CASH_PURCHASE_DISCOUNT_PROMOTION,
  DGB_PROMOTION,
  DISCOUNT_PROMOTION,
  FINANCING_DISCOUNT_PROMOTION,
  FINANCING_PROMOTION,
  PERCENT_DISCOUNT_PROMOTION,
  PICK_X_GET_Y_PERCENT_DISCOUNT_PROMOTION,
  SASA_PROMOTION,
  TWO_OR_MORE_IMPLMENTS_PROMOTION,
  WARRANTY_PERCENT_DISCOUNT_PROMOTION,
]

export const FINANCING_PROMOTION_DEFAULT = 'no'
