import { selector } from 'recoil'
import { isDefined, round, sum } from 'utils'
import { dgbSasaDiscountSelector, selectedOptionSelector, totalOptionDiscountsSelector } from '../'
import { equipmentMarginState } from '../../state'

const SELECTED_OPTION_PRICE_ADJUSTMENTS_SELECTOR = 'SELECTED_OPTION_PRICE_ADJUSTMENTS_SELECTOR'

/**
 * Margin adjustments. Margin is calculated initially with cash purchase discount applied. If financing is chosen, the
 * cash discount no longer applies and a different financing discount may now apply. This calculates the difference in
 * margin when that change occurs.
 */
const selectedOptionPriceAdjustmentsSelector = selector({
  key: SELECTED_OPTION_PRICE_ADJUSTMENTS_SELECTOR,
  get: ({ get }) => {
    const selectedOption = get(selectedOptionSelector)
    const totalOptionDiscounts = get(totalOptionDiscountsSelector)
    const dgbSasaDiscount = get(dgbSasaDiscountSelector)
    const equipmentMargin = get(equipmentMarginState)

    if (isDefined(selectedOption) && isDefined(equipmentMargin) && equipmentMargin !== 0) {
      const newCostWithDiscounts = [
        selectedOption.cost,
        -dgbSasaDiscount,
        -totalOptionDiscounts,
      ].reduce(sum)
      const newSalePrice = round(newCostWithDiscounts / (1 - equipmentMargin), 2)
      const newMarginAmount = round(newSalePrice - newCostWithDiscounts, 2)
      return round(selectedOption.marginAmount - newMarginAmount, 2)
    }

    return 0
  },
})

export default selectedOptionPriceAdjustmentsSelector
