import React from 'react'
import * as PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import ReactModal from 'react-modal'
import { MdClose } from 'react-icons/md'
import UnstyledButton from 'components/unstyled-button'
import { noop } from 'utils'

export const CONTENT_CLASS_NAME = 'modal-content'
export const OVERLAY_CLASS_NAME = 'modal-overlay'

ReactModal.setAppElement('#___gatsby')

const Modal = ({ children, isOpen, onRequestClose, style, toggle, ...props }) => {
  const closeModal = () => {
    toggle(false)
  }

  const handleRequestClose = () => {
    closeModal()
    onRequestClose()
  }
  return (
    <>
      <ModalStyle />
      <ReactModal isOpen={isOpen} onRequestClose={handleRequestClose} {...props}>
        {children}
        <Close onClick={closeModal} aria-label='Close'>
          <MdClose aria-hidden='true' focusable='false' role='presentation' />
        </Close>
      </ReactModal>
    </>
  )
}

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  overlayClassName: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
}

Modal.defaultProps = {
  className: CONTENT_CLASS_NAME,
  overlayClassName: OVERLAY_CLASS_NAME,
  onRequestClose: noop,
}

const ModalStyle = createGlobalStyle`
  .${CONTENT_CLASS_NAME} {
    background-color: #fff;
    border: none;
    border-radius: none;
    bottom: auto;
    height: auto;
    left: 50%;
    max-width: 600px;
    padding: var(--size-xl);
    position: absolute;
    right: auto;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .${OVERLAY_CLASS_NAME} {
    background-color: rgba(0, 0, 0, 0.25);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
  }

  @media screen and (max-width: 600px) {
    .${CONTENT_CLASS_NAME} {
      height: 100%;
      overflow-y: scroll;
      padding: var(--size-l) var(--size-m);
    }
  }

  @media print {
    display: none;

    .${CONTENT_CLASS_NAME} {
      display: none;
    }

    .${OVERLAY_CLASS_NAME} {
      display: none;
    }
  }
`

const Close = styled(UnstyledButton)`
  color: #444;
  cursor: pointer;
  font-size: 2em;
  position: absolute;
  right: 20px;
  top: 20px;
`

export default Modal
