import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import Spacer from 'components/spacer'
import Button from 'components/button'
import Disclaimer, { GENERIC_DISCLAIMER } from 'components/disclaimer'
import UnstyledButton from 'components/unstyled-button'
import SidebarSection from 'components/sidebar-section/sidebar-section'
import { Tooltip } from 'components/tooltip'
import { FaPrint, FaEnvelope } from 'react-icons/fa'
import { stringify } from 'querystring'
import { formatPrice, isDefined, isNumber, round } from 'utils'
import { fbq, fbqEvents, isBrowser, gtag, gtagEvents } from '@hutson/utils'
import { SUMMARY_TAB } from '../../constants'
import { clearfix, column } from 'styles'
import {
  attachmentPickXGetYDiscountPromotionsState,
  attachmentsState,
  cashPurchaseDiscountPromotionsState,
  discountPromotionsState,
  financingDiscountPromotionsState,
  formModalOpenState,
  implementIdsState,
  productDetailsState,
  tabState,
  twoOrMoreImplementPromotionsState,
} from '../../state'
import {
  additionalOptionsPriceSelector,
  attachmentPickXGetYDiscountSelector,
  buildDetailsSelector,
  grandTotalSelector,
  hasCashPurchaseDiscountPromotionsSelector,
  hasDiscountPromotionsSelector,
  hasFinancingDiscountPromotionsSelector,
  hasSelectedFinancingSelector,
  hasSelectedWarrantySelector,
  hasTwoOrMoreImplementPromotionsSelector,
  hutsonPriceSelector,
  hutsonSavingsSelector,
  paymentSelector,
  selectedAttachmentsDiscountSelector,
  selectedAttachmentsDiscountsSelector,
  selectedAttachmentsPriceSelector,
  selectedFinancingSelector,
  selectedImplementsPriceSelector,
  selectedOptionSelector,
  selectedWarrantySelector,
  subtotalSelector,
  totalAdditionalDiscountsSelector,
} from '../../selectors'
import { useDownPayment } from '../../hooks'

const SidebarSummary = () => {
  const tab = useRecoilValue(tabState)
  return (
    <StyledSidebarSummary hidden={tab !== SUMMARY_TAB}>
      <OptionSection />
      <AdditionalOptionsSection />
      <DiscountsSection />
      <SidebarSection>
        <SummaryTable>
          <tbody>
            <SubtotalRow />
          </tbody>
        </SummaryTable>
      </SidebarSection>
      <FinancingSection />
      <SidebarSection className='sidebar-section-bottom'>
        <SummaryTable>
          <tbody>
            <GrandTotalRow />
            <PaymentRow />
          </tbody>
        </SummaryTable>
        <Spacer size='xs' />
        <Disclaimer>
          Taxes, setup, delivery, and fees may not be included. Prices and availability may vary.
        </Disclaimer>
        <Spacer size='m' />
        <CtaButtons />
        <Spacer size='m' variable={false} />
        {tab === SUMMARY_TAB ? (
          <div>
            <PrintButton className='sidebar-save-button' />
            <EmailButton className='sidebar-save-button' />
          </div>
        ) : null}
      </SidebarSection>
      {/* TODO: Add custom disclaimer */}
      <Disclaimer className='desktop-hidden' />
    </StyledSidebarSummary>
  )
}

const StyledSidebarSummary = styled.div`
  .summary-price {
    font-size: 1.3125rem;
    font-weight: 600;
    margin: 0;

    .summary-price-append {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  .cta-buttons {
    a {
      display: inline-block;
      text-decoration: none;
    }
  }

  .main-cta-button {
    margin-bottom: var(--size-s);
  }

  .sidebar-save-button {
    color: var(--color-g400);
    display: inline-block;
    font-size: 1rem;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }

    svg {
      margin-right: var(--size-xs);
    }

    span,
    svg {
      vertical-align: middle;
    }

    :not(:last-child) {
      margin-bottom: var(--size-m);
      margin-right: var(--size-m);
    }
  }

  .sidebar-section-bottom {
    border-bottom: none;
  }

  @media screen and (max-width: 899px) {
    .cta-buttons {
      ${clearfix}

      > * {
        ${column('1/2', 16)}
      }
    }

    .main-cta-button {
      margin-bottom: 0;
    }

    .sidebar-save-button {
      :not(:last-child) {
        margin-bottom: var(--size-s);
      }
    }
  }

  @media print {
    display: block !important;

    .sidebar-save-button {
      display: none;
    }
  }
`

const SummaryTable = styled.table`
  border: none;
  border-collapse: collapse;
  font-size: 1.125rem;
  width: 100%;

  tr {
    td {
      display: inline-block;
      padding: 0;
      vertical-align: bottom;

      &.align-middle {
        vertical-align: middle;
      }

      :first-child {
        width: 60%;
      }

      :last-child {
        text-align: right;
        width: 40%;
      }
    }

    :not(:last-child) {
      td {
        padding: 0 0 var(--size-xs);
      }
    }
  }

  tr.subtotal {
    border-top: 1px dashed var(--color-n30);

    td {
      padding-top: var(--size-xs);
      font-weight: 700;
    }
  }
`

const DownPaymentInput = styled.input`
  box-sizing: border-box;
  padding: var(--size-xs) 0;
  text-align: right;
  width: 100px;

  @media screen and (max-width: 899px) {
    padding: var(--size-xs) var(--size-xxs);
  }
`

const DownPaymentPrintOnly = styled.div`
  display: none;

  @media print {
    display: block;
  }
`

const OptionSection = () => {
  const selectedOption = useRecoilValue(selectedOptionSelector)
  const hutsonSavings = useRecoilValue(hutsonSavingsSelector)
  const hutsonPrice = useRecoilValue(hutsonPriceSelector)
  const showMsrp =
    isDefined(selectedOption) && isNumber(selectedOption.msrp) && selectedOption.msrp !== 0
  return isDefined(selectedOption) ? (
    <SidebarSection>
      <SummaryTable>
        <tbody>
          {showMsrp ? (
            <tr>
              <td>MSRP</td>
              <td>{formatPrice(selectedOption.msrp)}</td>
            </tr>
          ) : selectedOption.price !== hutsonPrice ? (
            <tr>
              <td>List price</td>
              <td>{formatPrice(selectedOption.price)}</td>
            </tr>
          ) : null}
          {hutsonSavings !== 0 ? (
            <tr>
              <td>Hutson savings</td>
              <td>{formatPrice(-hutsonSavings)}</td>
            </tr>
          ) : null}
          <DiscountPromotionsRows />
          <CashPurchaseDiscountPromotionsRows />
          <FinancingDiscountPromotionsRows />
          <tr className={showMsrp && hutsonSavings !== 0 ? 'subtotal' : ''}>
            <td>
              <strong>Hutson price</strong>
            </td>
            <td>
              <strong>{formatPrice(hutsonPrice)}</strong>
            </td>
          </tr>
        </tbody>
      </SummaryTable>
    </SidebarSection>
  ) : null
}

const DiscountPromotionsRows = () => {
  const discountPromotions = useRecoilValue(discountPromotionsState)
  const hasDiscountPromotions = useRecoilValue(hasDiscountPromotionsSelector)
  return hasDiscountPromotions
    ? discountPromotions.map(promotion => (
        <tr key={promotion._id}>
          <td>
            {promotion.title}
            {isDefined(promotion.subtitle) ? ` ${promotion.subtitle}` : null}
            <Tooltip label={promotion.disclaimer} />
          </td>
          <td>{formatPrice(-promotion.details.discount)}</td>
        </tr>
      ))
    : null
}

const CashPurchaseDiscountPromotionsRows = () => {
  const cashPurchaseDiscountPromotions = useRecoilValue(cashPurchaseDiscountPromotionsState)
  const hasCashPurchaseDiscountPromotions = useRecoilValue(
    hasCashPurchaseDiscountPromotionsSelector
  )
  return hasCashPurchaseDiscountPromotions
    ? cashPurchaseDiscountPromotions.map(promotion => (
        <tr key={promotion._id}>
          <td>
            {promotion.title}
            {isDefined(promotion.subtitle) ? ` ${promotion.subtitle}` : null}
            <Tooltip label={promotion.disclaimer} />
          </td>
          <td>{formatPrice(-promotion.details.discount)}</td>
        </tr>
      ))
    : null
}

const FinancingDiscountPromotionsRows = () => {
  const financingDiscountPromotions = useRecoilValue(financingDiscountPromotionsState)
  const hasFinancingDiscountPromotions = useRecoilValue(hasFinancingDiscountPromotionsSelector)
  return hasFinancingDiscountPromotions
    ? financingDiscountPromotions.map(promotion => (
        <tr key={promotion._id}>
          <td>
            {promotion.title}
            {isDefined(promotion.subtitle) ? ` ${promotion.subtitle}` : null}
            <Tooltip label={promotion.disclaimer} />
          </td>
          <td>{formatPrice(-promotion.details.discount)}</td>
        </tr>
      ))
    : null
}

const AdditionalOptionsSection = () => {
  return (
    <SidebarSection>
      <SummaryTable>
        <tbody>
          <tr>
            <td>
              <strong>Additional options</strong>
            </td>
            <td />
          </tr>
          <ImplementsRow />
          <AttachmentsRow />
          <WarrantyRow />
          <ListPriceRow />
        </tbody>
      </SummaryTable>
    </SidebarSection>
  )
}

const ImplementsRow = () => {
  const implementIds = useRecoilValue(implementIdsState)
  const hasSelectedImplements = Array.isArray(implementIds) && implementIds.length > 0
  const selectedImplementsPrice = useRecoilValue(selectedImplementsPriceSelector)
  return hasSelectedImplements ? (
    <tr>
      <td>Implements</td>
      <td>{formatPrice(selectedImplementsPrice)}</td>
    </tr>
  ) : null
}

const AttachmentsRow = () => {
  const attachments = useRecoilValue(attachmentsState)
  const hasSelectedAttachments = Array.isArray(attachments) && attachments.length > 0
  const selectedAttachmentsPrice = useRecoilValue(selectedAttachmentsPriceSelector)
  const selectedAttachmentsDiscount = useRecoilValue(selectedAttachmentsDiscountSelector)
  return hasSelectedAttachments ? (
    <tr>
      <td>Attachments</td>
      <td>{formatPrice(selectedAttachmentsPrice + selectedAttachmentsDiscount)}</td>
    </tr>
  ) : null
}

const useWarrantyInfo = () => {
  const selectedWarranty = useRecoilValue(selectedWarrantySelector)
  const hasSelectedWarranty = useRecoilValue(hasSelectedWarrantySelector)
  const showDiscounts =
    hasSelectedWarranty &&
    isNumber(selectedWarranty.compareAtPrice) &&
    selectedWarranty.compareAtPrice > selectedWarranty.price &&
    isDefined(selectedWarranty.discountPromotion)

  return {
    hasSelectedWarranty,
    selectedWarranty,
    showDiscounts,
  }
}

const WarrantyRow = () => {
  const { hasSelectedWarranty, selectedWarranty, showDiscounts } = useWarrantyInfo()
  return hasSelectedWarranty ? (
    <tr>
      <td>Warranty</td>
      <td>
        {selectedWarranty.price === 0
          ? 'Included'
          : formatPrice(showDiscounts ? selectedWarranty.compareAtPrice : selectedWarranty.price)}
      </td>
    </tr>
  ) : null
}

const ListPriceRow = () => {
  const additionalOptionsPrice = useRecoilValue(additionalOptionsPriceSelector)
  return (
    <tr className='subtotal'>
      <td>Additional options total</td>
      <td>{formatPrice(additionalOptionsPrice)}</td>
    </tr>
  )
}

const DiscountsSection = () => {
  const totalAdditionalDiscounts = useRecoilValue(totalAdditionalDiscountsSelector)
  return totalAdditionalDiscounts !== 0 ? (
    <SidebarSection>
      <DiscountsSectionSummaryTable>
        <tbody>
          <tr>
            <td>
              <strong>Additional discounts</strong>
            </td>
            <td />
          </tr>
          <TwoOrMoreImplementPromotionsRows />
          <AttachmentDiscountsRows />
          <WarrantyDiscountsRow />
          <tr className='subtotal'>
            <td>Total additional discounts</td>
            <td>{formatPrice(-totalAdditionalDiscounts)}</td>
          </tr>
        </tbody>
      </DiscountsSectionSummaryTable>
    </SidebarSection>
  ) : null
}

const DiscountsSectionSummaryTable = styled(SummaryTable)`
  tr {
    svg {
      font-size: 1rem;
    }
  }
`

const WarrantyDiscountsRow = () => {
  const { selectedWarranty, showDiscounts } = useWarrantyInfo()
  return showDiscounts ? (
    <tr>
      <td>
        {selectedWarranty.discountPromotion.title}
        {isDefined(selectedWarranty.discountPromotion.subtitle)
          ? ` ${selectedWarranty.discountPromotion.subtitle}`
          : null}
        <Tooltip label={selectedWarranty.discountPromotion.disclaimer} />
      </td>
      <td>{formatPrice(-(selectedWarranty.compareAtPrice - selectedWarranty.price))}</td>
    </tr>
  ) : null
}

const TwoOrMoreImplementPromotionsRows = () => {
  const twoOrMoreImplementPromotions = useRecoilValue(twoOrMoreImplementPromotionsState)
  const hasTwoOrMoreImplementPromotions = useRecoilValue(hasTwoOrMoreImplementPromotionsSelector)
  return hasTwoOrMoreImplementPromotions
    ? twoOrMoreImplementPromotions.map(promotion => (
        <tr key={promotion._id}>
          <td>
            {promotion.title}
            {isDefined(promotion.subtitle) ? ` ${promotion.subtitle}` : null}
            <Tooltip label={promotion.disclaimer} />
          </td>
          <td>{formatPrice(-promotion.details.discount)}</td>
        </tr>
      ))
    : null
}

const AttachmentDiscountsRows = () => {
  const attachments = useRecoilValue(attachmentsState)
  const hasSelectedAttachments = Array.isArray(attachments) && attachments.length > 0
  const selectedAttachmentsDiscounts = useRecoilValue(selectedAttachmentsDiscountsSelector)
  const attachmentPickXGetYDiscount = useRecoilValue(attachmentPickXGetYDiscountSelector)
  const attachmentPickXGetYDiscountPromotions = useRecoilValue(
    attachmentPickXGetYDiscountPromotionsState
  )

  const hasSelectedAttachmentsDiscounts =
    Array.isArray(selectedAttachmentsDiscounts) && selectedAttachmentsDiscounts.length > 0
  return hasSelectedAttachments ? (
    <>
      {hasSelectedAttachmentsDiscounts
        ? selectedAttachmentsDiscounts.map(promotion => (
            <tr key={promotion._id}>
              <td>
                {promotion.title}
                {isDefined(promotion.subtitle) ? ` ${promotion.subtitle}` : null}
                <Tooltip label={promotion.disclaimer} />
              </td>
              <td>{formatPrice(-promotion.totalDiscount)}</td>
            </tr>
          ))
        : null}
      {attachmentPickXGetYDiscount !== 0 ? (
        <tr>
          <td>
            {attachmentPickXGetYDiscountPromotions[0].title}
            {isDefined(attachmentPickXGetYDiscountPromotions[0].subtitle)
              ? ` ${attachmentPickXGetYDiscountPromotions[0].subtitle}`
              : null}
            <Tooltip label={attachmentPickXGetYDiscountPromotions[0].disclaimer} />
          </td>
          <td>{formatPrice(-attachmentPickXGetYDiscount)}</td>
        </tr>
      ) : null}
    </>
  ) : null
}

const SubtotalRow = () => {
  const subtotal = useRecoilValue(subtotalSelector)
  return (
    <tr>
      <td>
        <strong>Subtotal</strong>
      </td>
      <td>
        <strong>{formatPrice(subtotal)}</strong>
      </td>
    </tr>
  )
}

const FinancingSection = () => {
  const hasSelectedFinancing = useRecoilValue(hasSelectedFinancingSelector)
  const subtotal = useRecoilValue(subtotalSelector)
  const { changeDownPayment, downPayment } = useDownPayment()

  const changeDownPaymentHandler = useCallback(
    e => {
      const newValue = Number(e.target.value)
      changeDownPayment(prevValue => {
        if (isNaN(newValue)) {
          return 0
        }
        if (newValue > subtotal) {
          return round(subtotal, 2)
        }
        if (newValue < 0) {
          return prevValue
        }
        return newValue
      })
    },
    [changeDownPayment, subtotal]
  )
  return hasSelectedFinancing ? (
    <SidebarSection>
      <SummaryTable>
        <tbody>
          <tr>
            <td className='align-middle'>Additional Down Payment</td>
            <td className='align-middle'>
              <span className='print-hidden'>$&nbsp;</span>
              <DownPaymentInput
                className='print-hidden'
                type='number'
                value={downPayment === 0 ? '' : downPayment}
                onChange={changeDownPaymentHandler}
                name='down-payment'
                placeholder='0'
                min='0'
                max={subtotal}
              />
              <DownPaymentPrintOnly>{formatPrice(downPayment)}</DownPaymentPrintOnly>
            </td>
          </tr>
        </tbody>
      </SummaryTable>
    </SidebarSection>
  ) : null
}

const GrandTotalRow = () => {
  const grandTotal = useRecoilValue(grandTotalSelector)
  return (
    <tr>
      <td>
        <strong>Grand total</strong>
      </td>
      <td>
        <strong>{formatPrice(grandTotal)}</strong>
      </td>
    </tr>
  )
}

const PaymentRow = () => {
  const payment = useRecoilValue(paymentSelector)
  const selectedFinancing = useRecoilValue(selectedFinancingSelector)
  return isDefined(payment) ? (
    <tr>
      <td style={{ width: '40%' }}>
        <strong>Payment</strong>
      </td>
      <td style={{ width: '60%' }}>
        <strong>{formatPrice(payment)}/month</strong>
        <Tooltip
          label={`${selectedFinancing.title}${
            isDefined(selectedFinancing.subtitle) ? ` ${selectedFinancing.subtitle}` : ''
          }. ${selectedFinancing.disclaimer}`}
        />
      </td>
    </tr>
  ) : null
}

const CtaButtons = () => {
  const { ocaLink } = useRecoilValue(productDetailsState)
  const setFormModalOpen = useSetRecoilState(formModalOpenState)
  const subtotal = useRecoilValue(subtotalSelector)

  const openForm = useCallback(() => {
    setFormModalOpen(true)
  }, [setFormModalOpen])

  const clickApplyForFinancing = useCallback(() => {
    gtag('event', gtagEvents.open_credit_application, {
      currency: 'USD',
      value: subtotal,
    })

    fbq('trackCustom', fbqEvents.Lead, {
      currency: 'USD',
      value: subtotal,
    })
  }, [subtotal])
  return (
    <div className='cta-buttons'>
      <Button color='yellow' className='main-cta-button' onClick={openForm}>
        Talk to sales
      </Button>
      <Button
        ghost
        as='a'
        href={ocaLink}
        target='_blank'
        rel='noopener noreferrer'
        onClick={clickApplyForFinancing}
      >
        Apply for financing
      </Button>
    </div>
  )
}

const PrintButton = props => {
  const { className } = props

  const handlePrint = useCallback(() => {
    if (isBrowser) {
      window.print()
      gtag('event', gtagEvents.print_page)
      fbq('trackCustom', fbqEvents.PrintPage)
    }
  }, [])
  return (
    <UnstyledButton className={className} onClick={handlePrint}>
      <FaPrint aria-hidden='true' focusable='false' role='presentation' />
      <span>Print</span>
    </UnstyledButton>
  )
}

const EmailButton = props => {
  const { className } = props

  const { title } = useRecoilValue(productDetailsState)
  const buildDetails = useRecoilValue(buildDetailsSelector)
  const selectedOption = useRecoilValue(selectedOptionSelector)
  const hasSelectedOption = isDefined(selectedOption)

  const subject = hasSelectedOption ? `Your ${title} Build` : ''
  // TODO: Update disclaimer
  const body = hasSelectedOption
    ? [
        `Your ${title} Build`,
        '---',
        buildDetails,
        '\n',
        'Disclaimer',
        '---',
        GENERIC_DISCLAIMER,
      ].join('\n')
    : ''

  const clickEmail = useCallback(() => {
    gtag('event', gtagEvents.share, {
      method: 'email',
    })
    fbq('trackCustom', fbqEvents.Share, {
      method: 'email',
    })
  }, [])
  return (
    <a href={`mailto:?${stringify({ body, subject })}`} className={className} onClick={clickEmail}>
      <FaEnvelope aria-hidden='true' focusable='false' role='presentation' />
      <span>Email</span>
    </a>
  )
}

export default SidebarSummary
