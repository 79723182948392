import { atom } from 'recoil'

export const WARRANTY_STATE = 'WARRANTY_STATE'

const warrantyState = atom({
  key: WARRANTY_STATE,
  default: null,
})

export default warrantyState
