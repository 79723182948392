import { selector } from 'recoil'
import { round, sum } from 'utils'
import { twoOrMoreImplementPromotionsState } from '../../state'
import {
  attachmentPickXGetYDiscountSelector,
  hasTwoOrMoreImplementPromotionsSelector,
  selectedAttachmentsDiscountSelector,
  selectedWarrantyDiscountSelector,
} from '../'

const TOTAL_ADDITIONAL_DISCOUNTS_SELECTOR = 'TOTAL_ADDITIONAL_DISCOUNTS_SELECTOR'

/**
 * Total discount on selected implements, attachments, and warranty.
 */
const totalAdditionalDiscountsSelector = selector({
  key: TOTAL_ADDITIONAL_DISCOUNTS_SELECTOR,
  get: ({ get }) => {
    const hasTwoOrMoreImplementPromotions = get(hasTwoOrMoreImplementPromotionsSelector)
    const selectedAttachmentsDiscount = get(selectedAttachmentsDiscountSelector)
    const attachmentPickXGetYDiscount = get(attachmentPickXGetYDiscountSelector)
    const twoOrMoreImplementPromotions = get(twoOrMoreImplementPromotionsState)
    const selectedWarrantyDiscount = get(selectedWarrantyDiscountSelector)

    const totalDiscount = [
      hasTwoOrMoreImplementPromotions
        ? twoOrMoreImplementPromotions.reduce((total, promotion) => {
            return sum(total, promotion.details.discount)
          }, 0)
        : 0,
      selectedAttachmentsDiscount,
      attachmentPickXGetYDiscount,
      selectedWarrantyDiscount,
    ].reduce(sum)

    return round(totalDiscount, 2)
  },
})

export default totalAdditionalDiscountsSelector
