import { selector } from 'recoil'
import { optionState } from '../state'
import { isDefined } from 'utils'

const HAS_SELECTED_OPTION_SELECTOR = 'HAS_SELECTED_OPTION_SELECTOR'

const hasSelectedOptionSelector = selector({
  key: HAS_SELECTED_OPTION_SELECTOR,
  get: ({ get }) => {
    return isDefined(get(optionState))
  },
})

export default hasSelectedOptionSelector
