import { selector } from 'recoil'
import { financingState, financingOptionsState } from '../state'

const SELECTED_FINANCING_SELECTOR = 'SELECTED_FINANCING_SELECTOR'

const selectedFinancingSelector = selector({
  key: SELECTED_FINANCING_SELECTOR,
  get: ({ get }) => {
    const financing = get(financingState)
    const financingOptions = get(financingOptionsState)

    return financingOptions.find(obj => obj._id === financing)
  },
})

export default selectedFinancingSelector
