import { selector } from 'recoil'
import { isDefined } from 'utils'
import { warrantyState } from '../state'

const HAS_SELECTED_WARRANTY_SELECTOR = 'HAS_SELECTED_WARRANTY_SELECTOR'

const hasSelectedWarrantySelector = selector({
  key: HAS_SELECTED_WARRANTY_SELECTOR,
  get: ({ get }) => {
    return isDefined(get(warrantyState))
  },
})

export default hasSelectedWarrantySelector
