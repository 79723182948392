import { selector } from 'recoil'
import { isDefined, sum } from 'utils'
import {
  additionalOptionsPriceSelector,
  hutsonPriceSelector,
  selectedOptionSelector,
  totalAdditionalDiscountsSelector,
} from '../'

const SUBTOTAL_SELECTOR = 'SUBTOTAL_SELECTOR'

/**
 * Subtotal before down payment.
 */
const subtotalSelector = selector({
  key: SUBTOTAL_SELECTOR,
  get: ({ get }) => {
    const selectedOption = get(selectedOptionSelector)

    if (!isDefined(selectedOption)) {
      return 0
    }

    const additionalOptionsPrice = get(additionalOptionsPriceSelector)
    const totalAdditionalDiscounts = get(totalAdditionalDiscountsSelector)
    const hutsonPrice = get(hutsonPriceSelector)

    const subtotal = [hutsonPrice, additionalOptionsPrice, -totalAdditionalDiscounts].reduce(sum)

    return subtotal
  },
})

export default subtotalSelector
