import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'
import Content from 'components/content'
import Disclaimer from 'components/disclaimer'
import OptionList from 'components/option-list/option-list'
import OptionListItem from 'components/option-list/option-list-item'
import OptionListSection from 'components/option-list/option-list-section'
import Spacer from 'components/spacer'
import Button from 'components/button'
import { isDefined } from 'utils'
import { ATTACHMENTS_PANEL, ATTACHMENTS_TAB } from '../../constants'
import { useAttachments, useTab } from '../../hooks'
import { enabledTabsSelector, hasCompatibleAttachmentOptionsSelector } from '../../selectors'
import { compatibleAttachmentsCategoriesState } from '../../state'

const AttachmentsPanel = () => {
  const compatibleAttachmentsCategories = useRecoilValue(compatibleAttachmentsCategoriesState)
  const { attachments, changeAttachment } = useAttachments()
  const { tab, changeTab } = useTab()
  const [nextTab, setNextTab] = useState()
  const enabledTabs = useRecoilValue(enabledTabsSelector)

  const hasSelectedAttachments = Array.isArray(attachments) && attachments.length > 0

  useEffect(() => {
    if (isDefined(enabledTabs)) {
      const currentTabIndex = enabledTabs.findIndex(obj => obj.tab === ATTACHMENTS_TAB)
      setNextTab(enabledTabs[currentTabIndex + 1])
    }
  }, [enabledTabs])
  return (
    <StyledContent
      id={ATTACHMENTS_PANEL}
      aria-labelledby={ATTACHMENTS_TAB}
      hidden={tab !== ATTACHMENTS_TAB}
    >
      <h2 className='mobile-hidden'>Attachments</h2>
      <Spacer className='mobile-hidden' />
      <div>
        {compatibleAttachmentsCategories.map(category => (
          <OptionListSection key={category.title}>
            <h3>{category.title}</h3>
            <Spacer size='s' />
            <OptionList>
              {category.attachments.map(attachment => (
                <OptionListItem
                  compareAtPrice={attachment.compareAtPrice}
                  compareAtPriceTooltip={
                    isDefined(attachment.discountPromotion)
                      ? `${attachment.discountPromotion.title}${
                          isDefined(attachment.discountPromotion.subtitle)
                            ? ` ${attachment.discountPromotion.subtitle}`
                            : ''
                        }. ${attachment.discountPromotion.disclaimer}`
                      : null
                  }
                  description={attachment.buildAndPriceDescriptionHtml}
                  images={attachment.images}
                  inputChangeHandler={changeAttachment}
                  inputChangeValue={attachment.id}
                  inputChecked={
                    hasSelectedAttachments ? attachments.includes(attachment.id) : false
                  }
                  inputId={`checkbox-attachments-${attachment.id}`}
                  inputName='attachment'
                  inputType='checkbox'
                  // moreDetailsLink={attachment.link}
                  price={attachment.price}
                  sku={`Part #${attachment.sku}`}
                  title={attachment.title}
                  key={attachment.id}
                />
              ))}
            </OptionList>
          </OptionListSection>
        ))}
      </div>
      <Spacer />
      {isDefined(nextTab) ? (
        <div className='next-button-container'>
          <Button aria-controls={nextTab.panel} onClick={() => changeTab(nextTab.tab)} ghost>
            Next: {nextTab.name}
          </Button>
        </div>
      ) : null}
      <Spacer size='l' />
      <Disclaimer />
    </StyledContent>
  )
}

const StyledContent = styled(Content)`
  .next-button-container {
    text-align: right;

    button {
      display: inline-block;
    }
  }
`

export default props => {
  const hasCompatibleAttachmentOptions = useRecoilValue(hasCompatibleAttachmentOptionsSelector)
  return hasCompatibleAttachmentOptions ? <AttachmentsPanel {...props} /> : null
}
