import { atom } from 'recoil'

export const DGB_PROMOTIONS_STATE = 'DGB_PROMOTIONS_STATE'

const dgbPromotionsState = atom({
  key: DGB_PROMOTIONS_STATE,
  default: [],
})

export default dgbPromotionsState
