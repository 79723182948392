import { atom } from 'recoil'

export const FINANCING_DISCOUNT_PROMOTIONS_STATE = 'FINANCING_DISCOUNT_PROMOTIONS_STATE'

const financingDiscountPromotionsState = atom({
  key: FINANCING_DISCOUNT_PROMOTIONS_STATE,
  default: [],
})

export default financingDiscountPromotionsState
