import { useCallback } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { downPaymentState, financingState } from '../state'
import { FINANCING_PROMOTION_DEFAULT } from '../constants'

const useFinancing = () => {
  const [financing, setFinancing] = useRecoilState(financingState)
  const setDownPayment = useSetRecoilState(downPaymentState)

  const changeFinancing = useCallback(
    val => {
      if (val === FINANCING_PROMOTION_DEFAULT) {
        setDownPayment(0)
      }
      setFinancing(val)
    },
    [setFinancing, setDownPayment]
  )

  return {
    changeFinancing,
    financing,
  }
}

export default useFinancing
