import { atom } from 'recoil'

export const DISCOUNT_PROMOTIONS_STATE = 'DISCOUNT_PROMOTIONS_STATE'

const discountPromotionsState = atom({
  key: DISCOUNT_PROMOTIONS_STATE,
  default: [],
})

export default discountPromotionsState
