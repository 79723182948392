import { selector } from 'recoil'
import { compatibleImplementsState } from '../state'

const HAS_COMPATIBLE_IMPLEMENT_OPTIONS_SELECTOR = 'HAS_COMPATIBLE_IMPLEMENT_OPTIONS_SELECTOR'

const hasCompatibleImplementOptionsSelector = selector({
  key: HAS_COMPATIBLE_IMPLEMENT_OPTIONS_SELECTOR,
  get: ({ get }) => {
    const compatibleImplements = get(compatibleImplementsState)
    return Array.isArray(compatibleImplements) && compatibleImplements.length > 0
  },
})

export default hasCompatibleImplementOptionsSelector
