import { selector } from 'recoil'
import { compatibleAttachmentsState } from '../state'

const HAS_COMPATIBLE_ATTACHMENT_OPTIONS_SELECTOR = 'HAS_COMPATIBLE_ATTACHMENT_OPTIONS_SELECTOR'

const hasCompatibleAttachmentOptionsSelector = selector({
  key: HAS_COMPATIBLE_ATTACHMENT_OPTIONS_SELECTOR,
  get: ({ get }) => {
    const compatibleAttachments = get(compatibleAttachmentsState)
    return Array.isArray(compatibleAttachments) && compatibleAttachments.length > 0
  },
})

export default hasCompatibleAttachmentOptionsSelector
