import { createGlobalStyle } from 'styled-components'

const TooltipStyle = createGlobalStyle`
  :root {
    --reach-tooltip: 1;
  }

  [data-reach-tooltip] {
    background-color: #fff;
    border: 1px solid var(--color-n20);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    color: var(--color-n400);
    font-size: 0.8125rem;
    line-height: 1.35;
    max-width: 240px;
    padding: var(--size-xs) var(--size-xs);
    pointer-events: none;
    position: absolute;
    z-index: 1;
  }
`

export default TooltipStyle
