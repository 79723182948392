import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const OptionList = ({ children, ...props }) => {
  return <StyledOptionList {...props}>{children}</StyledOptionList>
}

OptionList.propTypes = {
  children: PropTypes.node.isRequired,
}

const StyledOptionList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

export default OptionList
