import { selector } from 'recoil'
import { financingDiscountPromotionsState, financingState } from '../state'
import { FINANCING_PROMOTION_DEFAULT } from '../constants'

const HAS_FIANCING_DISCOUNT_PROMOTIONS_SELECTOR = 'HAS_FIANCING_DISCOUNT_PROMOTIONS_SELECTOR'

const hasFinancingDiscountPromotionsSelector = selector({
  key: HAS_FIANCING_DISCOUNT_PROMOTIONS_SELECTOR,
  get: ({ get }) => {
    const financingDiscountPromotions = get(financingDiscountPromotionsState)
    const financing = get(financingState)
    return (
      Array.isArray(financingDiscountPromotions) &&
      financingDiscountPromotions.length > 0 &&
      financing !== FINANCING_PROMOTION_DEFAULT
    )
  },
})

export default hasFinancingDiscountPromotionsSelector
