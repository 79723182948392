import { selector } from 'recoil'
import { subtotalSelector } from '../'
import { downPaymentState } from '../../state'
import { sum } from 'utils'

const GRAND_TOTAL_SELECTOR = 'GRAND_TOTAL_SELECTOR'

/**
 * Grand total.
 */
const grandTotalSelector = selector({
  key: GRAND_TOTAL_SELECTOR,
  get: ({ get }) => {
    const subtotal = get(subtotalSelector)
    const downPayment = get(downPaymentState)

    const grandTotal = [subtotal, -downPayment].reduce(sum)

    return grandTotal
  },
})

export default grandTotalSelector
