import React from 'react'
import styled from 'styled-components'

export const GENERIC_DISCLAIMER =
  'Prices and availability may vary by dealer. Financing subject to approved credit by John Deere Financial. Down payment may be required. Taxes, setup, delivery, and fees may not be included. Prices, options, and specifications are subject to change without notice. Prices shown are in U.S. dollars and valid only in the United States. Images for illustration purposes only. Restrictions may apply outside of Hutson’s area of responsibility. Terms and conditions may apply. See dealer for details.'

const Disclaimer = ({ children = GENERIC_DISCLAIMER, ...props }) => {
  return <StyledDisclaimer {...props}>{children}</StyledDisclaimer>
}

const StyledDisclaimer = styled.small`
  color: var(--color-n400);

  @media print {
    font-size: 0.875rem;
  }
`

export default Disclaimer
