import React from 'react'
import { RecoilRoot } from 'recoil'
import { graphql } from 'gatsby'
import ConfigureView from './configure'

const ConfigureTemplate = props => {
  return (
    <RecoilRoot>
      <ConfigureView {...props} />
    </RecoilRoot>
  )
}

export const pageQuery = graphql`
  query configureTemplate($id: String!) {
    sanityJohnDeereEquipment(id: { eq: $id }) {
      baseAttachments {
        id
      }
      category {
        handle
      }
      categoryCode
      compatibleAttachments {
        buildAndPriceDescriptionHtml
        category {
          handle
          id
          title
        }
        handle
        id
        images {
          asset {
            fluid(maxWidth: 640) {
              ...GatsbySanityImageFluid
            }
          }
        }
        link
        msrp
        price
        published
        sku
        title
      }
      compatibleImplements {
        brand {
          name
        }
        buildAndPriceDescriptionHtml
        category {
          handle
          margin
        }
        cost
        handle
        id
        images {
          asset {
            fluid(maxWidth: 640) {
              ...GatsbySanityImageFluid
            }
          }
        }
        link
        msrp
        published
        sku
        subcategory {
          applyEquipmentMargin
          handle
          id
          title
        }
        title
      }
      handle
      id
      options {
        _key
        advertisedPrice
        buildAndPriceDescriptionHtml
        cost
        images {
          asset {
            fluid(maxWidth: 640) {
              ...GatsbySanityImageFluid
            }
          }
        }
        msrp
        price
        title
      }
      margin
      series {
        handle
      }
      sku
      subcategory {
        handle
        ocaEquipmentType
        margin
        title
      }
      subcategoryCode
      title
      warranty {
        _key
        buildAndPriceDescriptionHtml
        default
        hours
        isFactory
        price
        years
        type {
          buildAndPriceDescriptionHtml
          link
          title
          type
        }
      }
    }
    allSanityPromotion(
      filter: {
        configureEnabled: { eq: true }
        eligibleEquipment: { elemMatch: { id: { eq: $id } } }
      }
    ) {
      nodes {
        _id
        details {
          dgb
          discount
          percentDiscount
          rate
          sasa
          term
        }
        disclaimer
        eligibleWarranties {
          type
        }
        endDate
        startDate
        subtitle
        title
        type
      }
    }
    attachmentPromotions: allSanityPromotion(
      filter: {
        configureEnabled: { eq: true }
        type: { in: ["percent-discount", "pick-x-get-y-percent-discount"] }
      }
    ) {
      nodes {
        _id
        details {
          percentDiscount
          pickXGetYPercentDiscountThresholds {
            amount
            percentDiscount
          }
        }
        disclaimer
        eligibleAttachments {
          id
        }
        endDate
        startDate
        subtitle
        title
        type
      }
    }
  }
`

export default ConfigureTemplate
