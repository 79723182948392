import { selector } from 'recoil'
import { isDefined, round, sum } from 'utils'
import { attachmentPickXGetYDiscountPromotionsState } from '../../state'
import { selectedAttachmentsSelector } from '../'

const ATTACHMENT_PICK_X_GET_Y_DISCOUNT_SELECTOR = 'ATTACHMENT_PICK_X_GET_Y_DISCOUNT_SELECTOR'

/**
 * Total discount from attachment pick x get y discounts (positive).
 */
const attachmentPickXGetYDiscountSelector = selector({
  key: ATTACHMENT_PICK_X_GET_Y_DISCOUNT_SELECTOR,
  get: ({ get }) => {
    const attachmentPickXGetYDiscountPromotions = get(attachmentPickXGetYDiscountPromotionsState)
    const selectedAttachments = get(selectedAttachmentsSelector)

    const hasSelectedAttachments =
      Array.isArray(selectedAttachments) && selectedAttachments.length > 0
    const hasDiscountPromotions =
      Array.isArray(attachmentPickXGetYDiscountPromotions) &&
      attachmentPickXGetYDiscountPromotions.length > 0

    if (!hasSelectedAttachments || !hasDiscountPromotions) {
      return 0
    }

    // Filter out attachments that are included in the discount promotion
    const selectedQualifyingAttachments = selectedAttachments.filter(attachment =>
      attachmentPickXGetYDiscountPromotions[0].eligibleAttachments.find(
        obj => obj.id === attachment.id
      )
    )
    const qualifyingAttachmentsCount = selectedQualifyingAttachments.length

    if (qualifyingAttachmentsCount === 0) {
      return 0
    }

    // Find the highest threshold that has been met (if it has been met)
    const highestThreshold =
      attachmentPickXGetYDiscountPromotions[0].details.pickXGetYPercentDiscountThresholds.reduce(
        (highest, current) => {
          if (qualifyingAttachmentsCount >= current.amount) {
            if (highest === null || current.amount > highest.amount) {
              return current
            }
          }
          return highest
        },
        null
      )

    // Take percent off each qualifying attachment and return the total discount
    if (isDefined(highestThreshold)) {
      const totalDiscount = selectedQualifyingAttachments.reduce((total, attachment) => {
        return sum(total, attachment.price * highestThreshold.percentDiscount)
      }, 0)
      return round(totalDiscount, 2)
    }

    return 0
  },
})

export default attachmentPickXGetYDiscountSelector
