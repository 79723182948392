import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import NoImage from 'components/no-image'
import UnstyledButton from 'components/unstyled-button'
import { Tooltip } from 'components/tooltip'
import { formatPrice, isDefined, isFunction, isNumber } from 'utils'
import { MdClose } from 'react-icons/md'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const SidebarSectionInner = ({
  images,
  moreDetailsLink,
  price,
  removeItem,
  showImage = true,
  title,
  tooltip,
  ...props
}) => {
  return (
    <StyledSidebarSectionInner {...props}>
      {showImage ? (
        <div className='inner-section-image'>
          {Array.isArray(images) && images.length > 0 ? (
            <Img
              fluid={{ ...images[0].asset.fluid, aspectRatio: 8 / 5 }}
              objectFit='contain'
              alt={title}
            />
          ) : (
            <NoImage />
          )}
        </div>
      ) : null}
      <div className='inner-section-text'>
        <p className='inner-section-title'>
          {title}
          {isDefined(tooltip) && tooltip !== '' ? <Tooltip label={tooltip} /> : null}
        </p>
        {isDefined(moreDetailsLink) ? (
          <OutboundLink
            className='inner-section-more-details'
            href={moreDetailsLink}
            target='_blank'
          >
            More details ›
          </OutboundLink>
        ) : null}
      </div>
      {isNumber(price) ? (
        <span className='inner-section-price'>{price === 0 ? 'Included' : formatPrice(price)}</span>
      ) : null}
      {isFunction(removeItem) ? (
        <UnstyledButton
          className='inner-section-remove-button'
          onClick={removeItem}
          aria-label='Remove'
          title='Remove'
        >
          <MdClose aria-hidden='true' focusable='false' role='presentation' />
        </UnstyledButton>
      ) : null}
    </StyledSidebarSectionInner>
  )
}

const StyledSidebarSectionInner = styled.div`
  align-items: center;
  display: flex;
  margin-top: var(--size-s);

  .inner-section-image {
    flex-shrink: 0;
    height: auto;
    margin-right: var(--size-s);
    width: 75px;
  }

  .inner-section-text {
    flex-grow: 2;
    margin-right: var(--size-s);
  }

  .inner-section-title {
    font-size: 0.875rem;
    font-weight: 700;
    margin: 0;
  }

  .inner-section-more-details {
    color: var(--color-g400);
    font-size: 0.875rem;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }

  .inner-section-price {
    font-size: 0.875rem;
    font-weight: 600;
  }

  .inner-section-remove-button {
    margin-left: var(--size-xs);
    vertical-align: middle;

    svg {
      color: var(--color-n100);
      font-size: 1.125rem;
    }

    :hover,
    :focus {
      svg {
        color: var(--color-r400);
      }
    }
  }
`

export default SidebarSectionInner
