import { selector } from 'recoil'
import { implementIdsState, compatibleImplementsState } from '../state'

const SELECTED_IMPLEMENTS_SELECTOR = 'SELECTED_IMPLEMENTS_SELECTOR'

const selectedImplementsSelector = selector({
  key: SELECTED_IMPLEMENTS_SELECTOR,
  get: ({ get }) => {
    const implementIds = get(implementIdsState)
    const compatibleImplements = get(compatibleImplementsState)

    if (Array.isArray(implementIds) && Array.isArray(compatibleImplements)) {
      return compatibleImplements.filter(implement => {
        return implementIds.find(val => val === implement.id)
      })
    }

    return null
  },
})

export default selectedImplementsSelector
