import { selector } from 'recoil'
import { formatPrice, isDefined, isNumber, round } from 'utils'
import { formatWarranty } from '../utils'
import {
  attachmentPickXGetYDiscountPromotionsState,
  cashPurchaseDiscountPromotionsState,
  discountPromotionsState,
  downPaymentState,
  financingDiscountPromotionsState,
  twoOrMoreImplementPromotionsState,
} from '../state'
import {
  attachmentPickXGetYDiscountSelector,
  grandTotalSelector,
  hasCashPurchaseDiscountPromotionsSelector,
  hasDiscountPromotionsSelector,
  hasFinancingDiscountPromotionsSelector,
  hasTwoOrMoreImplementPromotionsSelector,
  hutsonPriceSelector,
  hutsonSavingsSelector,
  paymentSelector,
  selectedAttachmentsDiscountsSelector,
  selectedAttachmentsPriceSelector,
  selectedAttachmentsSelector,
  selectedFinancingSelector,
  selectedImplementsPriceSelector,
  selectedImplementsSelector,
  selectedOptionSelector,
  selectedWarrantySelector,
} from '.'

const BUILD_DETAILS_SELECTOR = 'BUILD_DETAILS_SELECTOR'

const buildDetailsSelector = selector({
  key: BUILD_DETAILS_SELECTOR,
  get: ({ get }) => {
    const attachmentPickXGetYDiscount = get(attachmentPickXGetYDiscountSelector)
    const attachmentPickXGetYDiscountPromotions = get(attachmentPickXGetYDiscountPromotionsState)
    const cashPurchaseDiscountPromotions = get(cashPurchaseDiscountPromotionsState)
    const discountPromotions = get(discountPromotionsState)
    const downPayment = get(downPaymentState)
    const hutsonPrice = get(hutsonPriceSelector)
    const financingDiscountPromotions = get(financingDiscountPromotionsState)
    const grandTotal = get(grandTotalSelector)
    const hasCashPurchaseDiscountPromotions = get(hasCashPurchaseDiscountPromotionsSelector)
    const hasDiscountPromotions = get(hasDiscountPromotionsSelector)
    const hasFinancingDiscountPromotions = get(hasFinancingDiscountPromotionsSelector)
    const hasTwoOrMoreImplementPromotions = get(hasTwoOrMoreImplementPromotionsSelector)
    const hutsonSavings = get(hutsonSavingsSelector)
    const payment = get(paymentSelector)
    const selectedAttachments = get(selectedAttachmentsSelector)
    const selectedAttachmentsDiscounts = get(selectedAttachmentsDiscountsSelector)
    const selectedAttachmentsPrice = get(selectedAttachmentsPriceSelector)
    const selectedFinancing = get(selectedFinancingSelector)
    const selectedImplements = get(selectedImplementsSelector)
    const selectedImplementsPrice = get(selectedImplementsPriceSelector)
    const selectedOption = get(selectedOptionSelector)
    const selectedWarranty = get(selectedWarrantySelector)
    const twoOrMoreImplementPromotions = get(twoOrMoreImplementPromotionsState)

    const hasSelectedAttachments =
      Array.isArray(selectedAttachments) && selectedAttachments.length > 0
    const hasSelectedAttachmentsDiscounts =
      Array.isArray(selectedAttachmentsDiscounts) && selectedAttachmentsDiscounts.length > 0
    const hasSelectedFinancing = isDefined(selectedFinancing)
    const hasSelectedOption = isDefined(selectedOption)
    const hasSelectedImplements = Array.isArray(selectedImplements) && selectedImplements.length > 0
    const hasSelectedWarranty = isDefined(selectedWarranty)
    const showWarrantyDiscounts =
      hasSelectedWarranty &&
      isNumber(selectedWarranty.compareAtPrice) &&
      selectedWarranty.compareAtPrice > selectedWarranty.price &&
      isDefined(selectedWarranty.discountPromotion)
    const showMsrp = isNumber(selectedOption.msrp) && selectedOption.msrp !== 0
    const showAdditionalDiscounts =
      hasTwoOrMoreImplementPromotions ||
      hasSelectedAttachmentsDiscounts ||
      attachmentPickXGetYDiscount !== 0 ||
      showWarrantyDiscounts

    const buildDetails = hasSelectedOption
      ? [
          `Total Price: ${formatPrice(grandTotal)}`,
          isDefined(payment)
            ? `Payment: ${formatPrice(payment)}/month at ${round(
                selectedFinancing.details.rate * 100,
                2
              )}% for ${selectedFinancing.details.term} months with ${formatPrice(
                downPayment
              )} down`
            : null,
          'Note: Taxes, setup, delivery, and fees may not be included. Prices and availability may vary.',
          '\n',
          'Summary',
          '---',
          showMsrp
            ? `MSRP: ${formatPrice(selectedOption.msrp)}`
            : hutsonSavings !== 0
            ? `List price: ${formatPrice(selectedOption.price)}`
            : null,
          hutsonSavings !== 0 ? `- Hutson savings: ${formatPrice(-hutsonSavings)}` : null,
          ...(hasDiscountPromotions
            ? discountPromotions.map(promotion => {
                return `- ${promotion.title}${
                  isDefined(promotion.subtitle) ? ` ${promotion.subtitle}` : ''
                }: ${formatPrice(-promotion.details.discount)}`
              })
            : []),
          ...(hasCashPurchaseDiscountPromotions
            ? cashPurchaseDiscountPromotions.map(promotion => {
                return `- ${promotion.title}${
                  isDefined(promotion.subtitle) ? ` ${promotion.subtitle}` : ''
                }: ${formatPrice(-promotion.details.discount)}`
              })
            : []),
          ...(hasFinancingDiscountPromotions
            ? financingDiscountPromotions.map(promotion => {
                return `- ${promotion.title}${
                  isDefined(promotion.subtitle) ? ` ${promotion.subtitle}` : ''
                }: ${formatPrice(-promotion.details.discount)}`
              })
            : []),
          `Hutson price: ${formatPrice(hutsonPrice)}`,
          hasSelectedImplements ? `Implements: ${formatPrice(selectedImplementsPrice)}` : null,
          hasSelectedAttachments ? `Attachments: ${formatPrice(selectedAttachmentsPrice)}` : null,
          hasSelectedWarranty
            ? `Warranty: ${
                selectedWarranty.price === 0
                  ? 'Included'
                  : formatPrice(
                      showWarrantyDiscounts
                        ? selectedWarranty.compareAtPrice
                        : selectedWarranty.price
                    )
              }`
            : null,
          showAdditionalDiscounts ? 'Additional discounts:' : null,
          ...(hasTwoOrMoreImplementPromotions
            ? twoOrMoreImplementPromotions.map(promotion => {
                return `- ${promotion.title}${
                  isDefined(promotion.subtitle) ? ` ${promotion.subtitle}` : ''
                }: ${formatPrice(-promotion.details.discount)}`
              })
            : []),
          hasSelectedAttachmentsDiscounts
            ? selectedAttachmentsDiscounts.map(promotion => {
                return `- ${promotion.title}${
                  isDefined(promotion.subtitle) ? ` ${promotion.subtitle}` : ''
                }: ${formatPrice(-promotion.totalDiscount)}`
              })
            : null,
          attachmentPickXGetYDiscount !== 0
            ? `- ${attachmentPickXGetYDiscountPromotions[0].title}${
                isDefined(attachmentPickXGetYDiscountPromotions[0].subtitle)
                  ? ` ${attachmentPickXGetYDiscountPromotions[0].subtitle}`
                  : ''
              }: ${formatPrice(-attachmentPickXGetYDiscount)}`
            : null,
          showWarrantyDiscounts
            ? `- ${selectedWarranty.discountPromotion.title}${
                isDefined(selectedWarranty.discountPromotion.subtitle)
                  ? ` ${selectedWarranty.discountPromotion.subtitle}`
                  : ''
              }: ${formatPrice(-(selectedWarranty.compareAtPrice - selectedWarranty.price))}`
            : null,
          hasSelectedFinancing && isNumber(downPayment) && Number(downPayment) !== 0
            ? `Down Payment: ${formatPrice(Number(downPayment))}`
            : null,
          '\n',
          'More details',
          '---',
          'Base option:',
          `- ${selectedOption.title}: ${formatPrice(selectedOption.price)}`,
          ...(hasSelectedImplements
            ? [
                'Implements:',
                selectedImplements
                  .map(
                    implement =>
                      `- ${implement.title} (Part #${implement.sku}): ${formatPrice(
                        implement.price
                      )}`
                  )
                  .join('\n'),
              ]
            : []),
          ...(hasSelectedAttachments
            ? [
                'Attachments:',
                selectedAttachments
                  .map(
                    attachment =>
                      `- ${attachment.title} (Part #${attachment.sku}): ${formatPrice(
                        attachment.price
                      )}`
                  )
                  .join('\n'),
              ]
            : []),
          ...(hasSelectedWarranty
            ? [
                'Warranty:',
                `- ${formatWarranty(selectedWarranty)}: ${
                  selectedWarranty.price === 0 ? 'Included' : formatPrice(selectedWarranty.price)
                }`,
              ]
            : []),
        ]
          .filter(val => val)
          .join('\n')
      : ''
    return buildDetails
  },
})

export default buildDetailsSelector
