import * as PropTypes from 'prop-types'
import { useNoSSR } from 'hooks'

const NoSSR = ({ children }) => {
  const canRender = useNoSSR()
  return canRender ? children : null
}

NoSSR.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NoSSR
