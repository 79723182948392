import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Label from './label'
import SelectBase from './select-base'

const Select = ({ children, id, label, name, placeholder, register, required, ...props }) => {
  return (
    <>
      <Label htmlFor={id} required={required}>
        {label}
      </Label>
      <StyledSelectBase
        name={name}
        ref={register({ required })}
        id={id}
        required={required}
        {...props}
      >
        <option value='' disabled={required}>
          {placeholder}
        </option>
        {children}
      </StyledSelectBase>
    </>
  )
}

Select.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
}

Select.defaultProps = {
  placeholder: 'Select an option',
  required: false,
}

const StyledSelectBase = styled(SelectBase)`
  margin-top: var(--size-xs);
`

export default Select
