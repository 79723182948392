import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const Label = ({ children, htmlFor, required, ...props }) => {
  return (
    <StyledLabel htmlFor={htmlFor} data-required={required} {...props}>
      {children}
    </StyledLabel>
  )
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired,
}

const StyledLabel = styled.label`
  color: var(--color-n100);
  display: block;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0;
  padding: 4px 0 0;
  margin: 12px 0 0;

  &[data-required='true'] {
    :after {
      color: var(--color-r500);
      content: ' *';
      display: inline;
    }
  }

  @media screen and (max-width: 899px) {
    font-size: 0.875rem;
  }
`

export default Label
