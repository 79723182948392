import { selector } from 'recoil'
import {
  hasCompatibleAttachmentOptionsSelector,
  hasCompatibleImplementOptionsSelector,
  hasFinancingOptionsSelector,
  hasWarrantyOptionsSelector,
} from '../selectors'
import { ATTACHMENTS_TAB, FINANCING_TAB, IMPLEMENTS_TAB, TABS, WARRANTY_TAB } from '../constants'
import { isDefined } from 'utils'

const ENABLED_TABS_SELECTOR = 'ENABLED_TABS_SELECTOR'

const enabledTabsSelector = selector({
  key: ENABLED_TABS_SELECTOR,
  get: ({ get }) => {
    const hasCompatibleAttachmentOptions = get(hasCompatibleAttachmentOptionsSelector)
    const hasCompatibleImplementOptions = get(hasCompatibleImplementOptionsSelector)
    const hasFinancingOptions = get(hasFinancingOptionsSelector)
    const hasWarrantyOptions = get(hasWarrantyOptionsSelector)

    return TABS.map(obj => {
      if (obj.tab === ATTACHMENTS_TAB && !hasCompatibleAttachmentOptions) {
        return null
      }
      if (obj.tab === FINANCING_TAB && !hasFinancingOptions) {
        return null
      }
      if (obj.tab === IMPLEMENTS_TAB && !hasCompatibleImplementOptions) {
        return null
      }
      if (obj.tab === WARRANTY_TAB && !hasWarrantyOptions) {
        return null
      }
      return obj
    }).filter(isDefined)
  },
})

export default enabledTabsSelector
