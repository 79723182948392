import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Modal from 'components/modal'
import TalkToSalesForm from 'components/forms/talk-to-sales'
import NoSSR from 'components/no-ssr'
import MainContent from './components/main-content'
import Sidebar from './components/sidebar'
import BottomBar from './components/bottom-bar'
import { useMedia } from 'use-media'
import { isDefined, round, sortBy, sum } from 'utils'
import { gtag, isBrowser } from '@hutson/utils'
import { useIsMounted, useIsomorphicLayoutEffect, useEffectOnce } from 'hooks'
import { useFormModal } from './hooks'
import {
  CASH_PURCHASE_DISCOUNT_PROMOTION,
  DGB_PROMOTION,
  DISCOUNT_PROMOTION,
  FINANCING_DISCOUNT_PROMOTION,
  FINANCING_PROMOTION,
  OPTIONS,
  PERCENT_DISCOUNT_PROMOTION,
  PICK_X_GET_Y_PERCENT_DISCOUNT_PROMOTION,
  SASA_PROMOTION,
  TWO_OR_MORE_IMPLMENTS_PROMOTION,
  WARRANTY_PERCENT_DISCOUNT_PROMOTION,
} from './constants'
import { PrintStyles } from './styles'
import { TooltipStyle } from 'components/tooltip'
import {
  attachmentDiscountPromotionsState,
  attachmentPickXGetYDiscountPromotionsState,
  attachmentsState,
  cashPurchaseDiscountPromotionsState,
  compatibleAttachmentsState,
  compatibleAttachmentsCategoriesState,
  compatibleImplementsState,
  compatibleImplementsCategoriesState,
  dgbPromotionsState,
  discountPromotionsState,
  equipmentMarginState,
  financingDiscountPromotionsState,
  financingOptionsState,
  optionState,
  optionsState,
  panelsRefState,
  productDetailsState,
  sasaPromotionsState,
  tabState,
  twoOrMoreImplementPromotionsState,
  warrantyOptionsState,
  warrantyPercentDiscountPromotionsState,
  warrantyState,
} from './state'
import { buildDetailsSelector, subtotalSelector } from './selectors'

const ConfigureView = props => {
  const {
    data: {
      allSanityPromotion,
      attachmentPromotions,
      sanityJohnDeereEquipment: {
        baseAttachments,
        category,
        categoryCode,
        compatibleAttachments,
        compatibleImplements,
        options,
        sku,
        series,
        subcategory: { ocaEquipmentType },
        subcategory,
        subcategoryCode,
        title,
      },
      sanityJohnDeereEquipment,
    },
    location,
  } = props

  /**
   * Initialize product details states
   */
  const [productDetails, setProductDetails] = useRecoilState(productDetailsState)

  useEffect(() => {
    if (title !== productDetails.title) {
      setProductDetails({
        ...productDetails,
        title,
      })
    }
  }, [productDetails, setProductDetails, title])

  useEffect(() => {
    if (
      isDefined(category.handle) &&
      isDefined(subcategory.handle) &&
      isDefined(series.handle) &&
      isDefined(sku)
    ) {
      const newValue = `https://www.hutsoninc.com/${[
        category.handle,
        subcategory.handle,
        series.handle,
        sku,
      ].join('/')}/`
      if (productDetails.moreDetailsLink !== newValue) {
        setProductDetails({
          ...productDetails,
          moreDetailsLink: newValue,
        })
      }
    } else {
      setProductDetails({
        ...productDetails,
        moreDetailsLink: null,
      })
    }
  }, [category.handle, subcategory.handle, series.handle, sku, setProductDetails, productDetails])

  useEffect(() => {
    if (isDefined(ocaEquipmentType) && isDefined(sku)) {
      const newValue = `https://creditapp.financial.deere.com/?country=us&dealer=034321&ref=${sku}-build-and-price&type=${ocaEquipmentType}`
      if (productDetails.ocaLink !== newValue) {
        setProductDetails({
          ...productDetails,
          ocaLink: newValue,
        })
      }
    } else {
      setProductDetails({
        ...productDetails,
        ocaLink: null,
      })
    }
  }, [ocaEquipmentType, setProductDetails, sku, productDetails])

  /**
   * Initialize options state
   */
  const setOption = useSetRecoilState(optionState)
  const setOptions = useSetRecoilState(optionsState)
  const [equipmentMargin, setEquipmentMargin] = useRecoilState(equipmentMarginState)

  const [discountPromotions] = useRecoilState(discountPromotionsState)
  const [cashPurchaseDiscountPromotions] = useRecoilState(cashPurchaseDiscountPromotionsState)
  const [dgbPromotions] = useRecoilState(dgbPromotionsState)
  const [sasaPromotions] = useRecoilState(sasaPromotionsState)

  useEffect(() => {
    // Set margin
    const margin = sanityJohnDeereEquipment.margin || subcategory.margin
    setEquipmentMargin(isDefined(margin) && margin !== 0 ? margin : null)

    // Format options
    const optionsArr = Array.isArray(options) ? options : [options]
    const optionsFormatted = optionsArr.map(opt => {
      const { cost } = opt
      if (!isDefined(margin) || margin === 0 || !isDefined(cost) || cost === 0) {
        return {
          ...opt,
          marginAmount: 0,
        }
      }
      // Calculate margin amount. We store the margin amount to use later when calculating price adjustments from
      // margin changes.
      const costWithCashDiscounts = [
        cost,
        ...(Array.isArray(discountPromotions) && discountPromotions.length > 0
          ? discountPromotions.map(promotion => -promotion.details.discount)
          : [0]),
        ...(Array.isArray(cashPurchaseDiscountPromotions) &&
        cashPurchaseDiscountPromotions.length > 0
          ? cashPurchaseDiscountPromotions.map(promotion => -promotion.details.discount)
          : [0]),
        ...(Array.isArray(dgbPromotions) && dgbPromotions.length > 0
          ? dgbPromotions.map(promotion => -promotion.details.dgb)
          : [0]),
        ...(Array.isArray(sasaPromotions) && sasaPromotions.length > 0
          ? sasaPromotions.map(promotion => -promotion.details.sasa)
          : [0]),
      ].reduce(sum)
      const salePrice = costWithCashDiscounts / (1 - margin)
      const marginAmount = round(salePrice - costWithCashDiscounts, 2)
      return {
        ...opt,
        marginAmount,
      }
    })
    setOptions(optionsFormatted)
    if (optionsArr.length === 1) {
      setOption(optionsFormatted[0]._key)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    discountPromotions,
    cashPurchaseDiscountPromotions,
    dgbPromotions,
    sasaPromotions,
    setOptions,
    setOption,
    setEquipmentMargin,
  ])

  /**
   * Initialize compatible implements state
   */
  const setCompatibleImplements = useSetRecoilState(compatibleImplementsState)
  const setCompatibleImplementsCategories = useSetRecoilState(compatibleImplementsCategoriesState)

  useEffect(() => {
    if (Array.isArray(compatibleImplements) && compatibleImplements.length > 0) {
      const compatibleImplementsFormatted = compatibleImplements.map(imp => {
        const {
          // cost,
          msrp,
        } = imp
        // Calculate price
        // const margin = imp.subcategory.applyEquipmentMargin ? equipmentMargin : imp.category.margin
        // const price = round(cost / (1 - margin), 2)
        const price = round(msrp, 2)
        return {
          ...imp,
          price,
        }
      })

      const compatibleImplementsSorted = sortBy([...compatibleImplementsFormatted], 'price').filter(
        implement => implement.published
      )

      setCompatibleImplements(compatibleImplementsSorted)

      const formattedImplementCategories = compatibleImplementsSorted.reduce((acc, implement) => {
        const subcategory = implement.subcategory.title
        // Check if category already exists
        const subcategoryMatchIndex = acc.findIndex(obj => obj.title === subcategory)
        if (subcategoryMatchIndex !== -1) {
          // If subcategory exists, add to it
          acc[subcategoryMatchIndex].implements.push(implement)
        } else {
          // Otherwise, create a new one
          acc.push({
            title: subcategory,
            implements: [implement],
          })
        }
        return acc
      }, [])

      setCompatibleImplementsCategories(sortBy(formattedImplementCategories, 'title'))
    } else {
      setCompatibleImplements(compatibleImplements)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentMargin, setCompatibleImplements, setCompatibleImplementsCategories])

  /**
   * Initialize compatible attachments state
   */
  const setAttachments = useSetRecoilState(attachmentsState)
  const setCompatibleAttachments = useSetRecoilState(compatibleAttachmentsState)
  const setCompatibleAttachmentsCategories = useSetRecoilState(compatibleAttachmentsCategoriesState)
  const setAttachmentDiscountPromotions = useSetRecoilState(attachmentDiscountPromotionsState)
  const setAttachmentPickXGetYDiscountPromotions = useSetRecoilState(
    attachmentPickXGetYDiscountPromotionsState
  )

  useEffectOnce(() => {
    if (Array.isArray(baseAttachments) && baseAttachments.length > 0) {
      setAttachments(baseAttachments.map(attachment => attachment.id))
    }
  })

  useEffectOnce(() => {
    if (Array.isArray(compatibleAttachments) && compatibleAttachments.length > 0) {
      // Filter out unpublished attachments
      const compatibleAttachmentsFiltered = compatibleAttachments.filter(
        attachment => attachment.published
      )

      // Get active attachment discount promotions
      const attachmentDiscountPromotions = attachmentPromotions.nodes.filter(node => {
        // Filter out promotions that are expired or haven't started
        const currentTimestamp = Date.now()
        const startDateTimestamp = new Date(node.startDate).getTime()
        const endDateTimestamp = new Date(node.endDate).getTime() + 1000 * 60 * 60 * 24 // Add 1 day

        if (currentTimestamp < startDateTimestamp || currentTimestamp >= endDateTimestamp) {
          return false
        }

        // Filter out promotions that don't apply to any attachments on the selected equipment
        for (let i = 0; i < node.eligibleAttachments.length; i++) {
          const matchIndex = compatibleAttachmentsFiltered.findIndex(
            obj => obj.id === node.eligibleAttachments[i].id
          )
          if (matchIndex >= 0) {
            return true
          }
        }
        return false
      })

      setAttachmentDiscountPromotions(
        attachmentDiscountPromotions.filter(
          promotion => promotion.type === PERCENT_DISCOUNT_PROMOTION
        )
      )
      setAttachmentPickXGetYDiscountPromotions(
        attachmentDiscountPromotions.filter(
          promotion => promotion.type === PICK_X_GET_Y_PERCENT_DISCOUNT_PROMOTION
        )
      )

      const compatibleAttachmentsFormatted = compatibleAttachmentsFiltered.map(attachment => {
        const eligiblePromotions = attachmentDiscountPromotions.filter(node =>
          node.eligibleAttachments.find(obj => obj.id === attachment.id)
        )

        // Find best discount if multiple are available (shouldn't ever happen, but we know how that goes)
        let bestDiscountPromotion = null

        for (let i = 0; i < eligiblePromotions.length; i++) {
          // Check if best warranty discount has been defined or if the current one has a higher discount
          if (
            bestDiscountPromotion === null ||
            eligiblePromotions[i].details.percentDiscount >
              bestDiscountPromotion.details.percentDiscount
          ) {
            bestDiscountPromotion = eligiblePromotions[i]
          }
        }

        return {
          ...attachment,
          ...(bestDiscountPromotion !== null
            ? {
                price: round(
                  attachment.price * (1 - bestDiscountPromotion.details.percentDiscount),
                  2
                ),
                compareAtPrice: attachment.price,
                discountId: bestDiscountPromotion._id,
                discountPromotion: bestDiscountPromotion,
              }
            : {}),
        }
      })

      const compatibleAttachmentsSorted = sortBy(compatibleAttachmentsFormatted, 'price')

      setCompatibleAttachments(compatibleAttachmentsSorted)

      const formattedAttachmentCategories = compatibleAttachmentsSorted.reduce(
        (acc, attachment) => {
          const category = attachment.category.title
          // Check if category already exists
          const categoryMatchIndex = acc.findIndex(obj => obj.title === category)
          if (categoryMatchIndex !== -1) {
            // If category exists, add to it
            acc[categoryMatchIndex].attachments.push(attachment)
          } else {
            // Otherwise, create a new one
            acc.push({
              title: category,
              attachments: [attachment],
            })
          }
          return acc
        },
        []
      )

      setCompatibleAttachmentsCategories(sortBy(formattedAttachmentCategories, 'title'))
    } else {
      setCompatibleAttachments(compatibleAttachments)
    }
  })

  /**
   * Initialize warranty options and promotions states
   */
  const setWarranty = useSetRecoilState(warrantyState)
  const setWarrantyOptions = useSetRecoilState(warrantyOptionsState)
  const setWarrantyPercentDiscountPromotions = useSetRecoilState(
    warrantyPercentDiscountPromotionsState
  )

  useEffectOnce(() => {
    // Check for warranty discounts
    const warrantyDiscounts = allSanityPromotion.nodes.filter(node => {
      if (node.type !== WARRANTY_PERCENT_DISCOUNT_PROMOTION) {
        return false
      }
      const currentTimestamp = Date.now()
      const startDateTimestamp = new Date(node.startDate).getTime()
      const endDateTimestamp = new Date(node.endDate).getTime() + 1000 * 60 * 60 * 24 // Add 1 day
      return currentTimestamp >= startDateTimestamp && currentTimestamp < endDateTimestamp
    })
    setWarrantyPercentDiscountPromotions(warrantyDiscounts)

    // Apply warranty discounts if available
    const warrantiesWithOptionalDiscountsApplied =
      warrantyDiscounts.length > 0
        ? sanityJohnDeereEquipment.warranty.map(equipmentWarranty => {
            // Find best discount if multiple are available (shouldn't ever happen, but we know how that goes)
            let bestWarrantyDiscountMatch = null

            for (let i = 0; i < warrantyDiscounts.length; i++) {
              // Check if discount warranty type matches equipment warranty type
              const eligibleWarrantiesMatchIndex = warrantyDiscounts[
                i
              ].eligibleWarranties.findIndex(obj => obj.type === equipmentWarranty.type.type)
              if (eligibleWarrantiesMatchIndex !== -1) {
                // Check if best warranty discount has been defined or if the current one has a higher discount
                if (
                  bestWarrantyDiscountMatch === null ||
                  warrantyDiscounts[i].details.percentDiscount >
                    bestWarrantyDiscountMatch.details.percentDiscount
                ) {
                  bestWarrantyDiscountMatch = warrantyDiscounts[i]
                }
              }
            }
            return {
              ...equipmentWarranty,
              ...(bestWarrantyDiscountMatch !== null
                ? {
                    price: round(
                      equipmentWarranty.price *
                        (1 - bestWarrantyDiscountMatch.details.percentDiscount),
                      2
                    ),
                    compareAtPrice: equipmentWarranty.price,
                    discountId: bestWarrantyDiscountMatch._id,
                    discountPromotion: bestWarrantyDiscountMatch,
                  }
                : {}),
            }
          })
        : sanityJohnDeereEquipment.warranty

    // Sort by price
    const warranties = [...sortBy(warrantiesWithOptionalDiscountsApplied, 'price')]

    // Move factory warranty to beginning
    const factoryWarrantyIndex = warranties.findIndex(obj => obj.isFactory)
    const factoryWarranty = warranties.splice(factoryWarrantyIndex, 1)
    const warrantyOptionsFormatted = factoryWarranty.concat(warranties).map(obj => {
      // Format warranty
      return {
        ...obj,
        buildAndPriceDescriptionHtml: obj.buildAndPriceDescriptionHtml
          ? obj.buildAndPriceDescriptionHtml
          : obj.type.buildAndPriceDescriptionHtml,
      }
    })

    const defaultWarrantyMatch = warrantyOptionsFormatted.find(obj => obj.default)

    setWarranty(defaultWarrantyMatch ? defaultWarrantyMatch._key : warrantyOptionsFormatted[0]._key)
    setWarrantyOptions(warrantyOptionsFormatted)
  })

  /**
   * Initialize financing options state
   */
  const setFinancingOptions = useSetRecoilState(financingOptionsState)

  useEffectOnce(() => {
    const options = allSanityPromotion.nodes.filter(node => {
      if (node.type !== FINANCING_PROMOTION) {
        return false
      }
      const currentTimestamp = Date.now()
      const startDateTimestamp = new Date(node.startDate).getTime()
      const endDateTimestamp = new Date(node.endDate).getTime() + 1000 * 60 * 60 * 24 // Add 1 day
      return currentTimestamp >= startDateTimestamp && currentTimestamp < endDateTimestamp
    })

    options.sort((a, b) => {
      if (a.details.term > b.details.term) return 1
      if (a.details.term < b.details.term) return -1
      if (a.details.rate > b.details.rate) return 1
      if (a.details.rate < b.details.rate) return -1
      return 0
    })

    setFinancingOptions(options)
  })

  /**
   * Initialize promotion states
   */
  const setDiscountPromotions = useSetRecoilState(discountPromotionsState)
  const setCashPurchaseDiscountPromotions = useSetRecoilState(cashPurchaseDiscountPromotionsState)
  const setFinancingDiscountPromotions = useSetRecoilState(financingDiscountPromotionsState)
  const setTwoOrMoreImplementPromotions = useSetRecoilState(twoOrMoreImplementPromotionsState)
  const setDgbPromotions = useSetRecoilState(dgbPromotionsState)
  const setSasaPromotions = useSetRecoilState(sasaPromotionsState)

  useEffectOnce(() => {
    const currentTimestamp = Date.now()
    setDiscountPromotions(
      allSanityPromotion.nodes.filter(node => {
        if (node.type !== DISCOUNT_PROMOTION) {
          return false
        }
        const startDateTimestamp = new Date(node.startDate).getTime()
        const endDateTimestamp = new Date(node.endDate).getTime() + 1000 * 60 * 60 * 24 // Add 1 day
        return currentTimestamp >= startDateTimestamp && currentTimestamp < endDateTimestamp
      })
    )
    setCashPurchaseDiscountPromotions(
      allSanityPromotion.nodes.filter(node => {
        if (node.type !== CASH_PURCHASE_DISCOUNT_PROMOTION) {
          return false
        }
        const startDateTimestamp = new Date(node.startDate).getTime()
        const endDateTimestamp = new Date(node.endDate).getTime() + 1000 * 60 * 60 * 24 // Add 1 day
        return currentTimestamp >= startDateTimestamp && currentTimestamp < endDateTimestamp
      })
    )
    setFinancingDiscountPromotions(
      allSanityPromotion.nodes.filter(node => {
        if (node.type !== FINANCING_DISCOUNT_PROMOTION) {
          return false
        }
        const startDateTimestamp = new Date(node.startDate).getTime()
        const endDateTimestamp = new Date(node.endDate).getTime() + 1000 * 60 * 60 * 24 // Add 1 day
        return currentTimestamp >= startDateTimestamp && currentTimestamp < endDateTimestamp
      })
    )
    setTwoOrMoreImplementPromotions(
      allSanityPromotion.nodes.filter(node => {
        if (node.type !== TWO_OR_MORE_IMPLMENTS_PROMOTION) {
          return false
        }
        const startDateTimestamp = new Date(node.startDate).getTime()
        const endDateTimestamp = new Date(node.endDate).getTime() + 1000 * 60 * 60 * 24 // Add 1 day
        return currentTimestamp >= startDateTimestamp && currentTimestamp < endDateTimestamp
      })
    )
    setDgbPromotions(
      allSanityPromotion.nodes.filter(node => {
        if (node.type !== DGB_PROMOTION) {
          return false
        }
        const startDateTimestamp = new Date(node.startDate).getTime()
        const endDateTimestamp = new Date(node.endDate).getTime() + 1000 * 60 * 60 * 24 // Add 1 day
        return currentTimestamp >= startDateTimestamp && currentTimestamp < endDateTimestamp
      })
    )
    setSasaPromotions(
      allSanityPromotion.nodes.filter(node => {
        if (node.type !== SASA_PROMOTION) {
          return false
        }
        const startDateTimestamp = new Date(node.startDate).getTime()
        const endDateTimestamp = new Date(node.endDate).getTime() + 1000 * 60 * 60 * 24 // Add 1 day
        return currentTimestamp >= startDateTimestamp && currentTimestamp < endDateTimestamp
      })
    )
  })

  /**
   * Component state
   */
  const isDesktop = useMedia({ minWidth: 900 })
  const isMounted = useIsMounted()

  /**
   * Analytics
   */
  useEffectOnce(() => {
    // Initial screen view
    gtag('event', 'screen_view', { screen_name: OPTIONS })
  })
  return (
    <>
      <Layout seo={{ title }} title={title}>
        <Helmet>
          <meta name='robots' content='noindex' />
        </Helmet>
        {isMounted() ? (
          <>
            <PrintStyles />
            <TooltipStyle />
            <PageGrid>
              <MainContent />
              <Sidebar isDesktop={isDesktop} />
            </PageGrid>
          </>
        ) : (
          <LoadingScreen>
            <LoadingCircle />
            <LoadingText>Loading Build Your Own App</LoadingText>
            <LoadingSubtext>This should only take a moment...</LoadingSubtext>
          </LoadingScreen>
        )}
      </Layout>
      {isMounted() ? (
        <>
          {!isDesktop ? <BottomBar /> : null}
          <FormModal
            categoryCode={categoryCode}
            pageURL={location.href}
            productName={title}
            subcategoryCode={subcategoryCode}
            subcategoryTitle={subcategory.title}
            title={title}
          />
          <NoSSR>
            <LayoutEffects isDesktop={isDesktop} />
          </NoSSR>
        </>
      ) : null}
    </>
  )
}

const LoadingScreen = styled.div`
  align-items: center;
  background-color: #fff;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10000;
`

const spinAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const LoadingCircle = styled.div`
  animation: ${spinAnimation} 0.67s infinite linear;
  border: 4px solid var(--color-n20);
  border-radius: 100%;
  border-top-color: var(--color-g400);
  box-sizing: border-box;
  height: 30px;
  width: 30px;
`

const LoadingText = styled.p`
  font-weight: bold;
  margin-bottom: var(--size-xxs);
  text-align: center;
`

const LoadingSubtext = styled.p`
  color: var(--color-n400);
  font-size: 0.875rem;
  margin: 0;
  text-align: center;
`

const PageGrid = styled.div`
  display: flex;
  height: 100%;

  @media screen and (max-width: 899px) {
    display: block;
    height: auto;
  }

  @media print {
    display: block;
  }
`

const FormModal = ({ categoryCode, pageURL, subcategoryCode, subcategoryTitle, title }) => {
  const [formModalOpen, toggleFormModalOpen] = useFormModal()
  return (
    <Modal isOpen={formModalOpen} toggle={toggleFormModalOpen}>
      {formModalOpen ? (
        <Form
          categoryCode={categoryCode}
          pageURL={pageURL}
          subcategoryCode={subcategoryCode}
          subcategoryTitle={subcategoryTitle}
          title={title}
        />
      ) : null}
    </Modal>
  )
}

const Form = ({ categoryCode, pageURL, subcategoryCode, title }) => {
  const subtotal = useRecoilValue(subtotalSelector)
  const buildDetails = useRecoilValue(buildDetailsSelector)
  return (
    <TalkToSalesForm
      buildDetails={buildDetails}
      categoryCode={categoryCode}
      pageURL={pageURL}
      productName={title}
      productPrice={subtotal}
      productType='john-deere'
      subcategoryCode={subcategoryCode}
    />
  )
}

const LayoutEffects = ({ isDesktop }) => {
  const tab = useRecoilValue(tabState)
  const panelsRef = useRecoilValue(panelsRefState)

  // Change tab
  useIsomorphicLayoutEffect(() => {
    if (isBrowser) {
      // Scroll to top of panel
      if (isDesktop) {
        if (isDefined(panelsRef)) {
          panelsRef.scroll({
            top: 0,
          })
        }
      } else {
        const headerEl = document.getElementById('header')
        window.scrollTo({
          top: isDefined(headerEl) ? headerEl.offsetHeight : 0,
        })
      }

      // Scroll selected tab into view if needed
      const tabEl = document.getElementById(tab)
      const tablistEl = document.getElementById('tablist')
      if (tabEl && tablistEl) {
        const windowOuterWidth = window.outerWidth
        const tabElOffsetLeft = tabEl.offsetLeft
        const tabElOffsetWidth = tabEl.offsetWidth
        const tablistElScrollLeft = tablistEl.scrollLeft
        if (tablistElScrollLeft > tabElOffsetLeft) {
          // Scroll backwards
          tablistEl.scrollTo({ left: tabElOffsetLeft, behavior: 'smooth' })
        } else if (tablistElScrollLeft + windowOuterWidth < tabElOffsetLeft + tabElOffsetWidth) {
          // Scroll forwards
          tablistEl.scrollTo({
            left: tabElOffsetLeft + tabElOffsetWidth - windowOuterWidth,
            behavior: 'smooth',
          })
        }
      }
    }
  }, [tab]) // eslint-disable-line react-hooks/exhaustive-deps
  return null
}

export default ConfigureView
