import { selector } from 'recoil'
import { discountPromotionsState } from '../state'

const HAS_DISCOUNT_PROMOTIONS_SELECTOR = 'HAS_DISCOUNT_PROMOTIONS_SELECTOR'

const hasDiscountPromotionsSelector = selector({
  key: HAS_DISCOUNT_PROMOTIONS_SELECTOR,
  get: ({ get }) => {
    const discountPromotions = get(discountPromotionsState)
    return Array.isArray(discountPromotions) && discountPromotions.length > 0
  },
})

export default hasDiscountPromotionsSelector
