import { selector } from 'recoil'
import { cashPurchaseDiscountPromotionsState, financingState } from '../state'
import { FINANCING_PROMOTION_DEFAULT } from '../constants'

const HAS_CASH_PURCHASE_DISCOUNT_PROMOTIONS_SELECTOR =
  'HAS_CASH_PURCHASE_DISCOUNT_PROMOTIONS_SELECTOR'

const hasCashPurchaseDiscountPromotionsSelector = selector({
  key: HAS_CASH_PURCHASE_DISCOUNT_PROMOTIONS_SELECTOR,
  get: ({ get }) => {
    const cashPurchaseDiscountPromotions = get(cashPurchaseDiscountPromotionsState)
    const financing = get(financingState)
    return (
      Array.isArray(cashPurchaseDiscountPromotions) &&
      cashPurchaseDiscountPromotions.length > 0 &&
      financing === FINANCING_PROMOTION_DEFAULT
    )
  },
})

export default hasCashPurchaseDiscountPromotionsSelector
