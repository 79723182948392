import { selector } from 'recoil'
import { isDefined, round } from 'utils'
import { selectedOptionSelector } from '../'

const SELECTED_OPTION_MSRP_DISCOUNT_SELECTOR = 'SELECTED_OPTION_MSRP_DISCOUNT_SELECTOR'

/**
 * Total discount from MSRP.
 */
const selectedOptionMsrpDiscountSelector = selector({
  key: SELECTED_OPTION_MSRP_DISCOUNT_SELECTOR,
  get: ({ get }) => {
    const selectedOption = get(selectedOptionSelector)

    if (!isDefined(selectedOption) || !isDefined(selectedOption.msrp)) {
      return 0
    }

    // const priceToUse = isDefined(selectedOption.cost) ? 'cost' : 'price'
    const priceToUse = isDefined(selectedOption.advertisedPrice) ? 'advertisedPrice' : 'price'

    return selectedOption.msrp > selectedOption[priceToUse]
      ? round(selectedOption.msrp - selectedOption[priceToUse], 2)
      : 0
  },
})

export default selectedOptionMsrpDiscountSelector
