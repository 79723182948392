import { selector } from 'recoil'
import { twoOrMoreImplementPromotionsState } from '../state'
import { selectedImplementsSelector } from '../selectors'
import { isDefined } from 'utils'

const HAS_TWO_OR_MORE_IMPLEMENT_PROMOTIONS_SELECTOR =
  'HAS_TWO_OR_MORE_IMPLEMENT_PROMOTIONS_SELECTOR'

const hasTwoOrMoreImplementPromotionsSelector = selector({
  key: HAS_TWO_OR_MORE_IMPLEMENT_PROMOTIONS_SELECTOR,
  get: ({ get }) => {
    const twoOrMoreImplementPromotions = get(twoOrMoreImplementPromotionsState)
    const selectedImplements = get(selectedImplementsSelector)

    const hasSelectedImplements = Array.isArray(selectedImplements) && selectedImplements.length > 0
    return (
      Array.isArray(twoOrMoreImplementPromotions) &&
      twoOrMoreImplementPromotions.length > 0 &&
      hasSelectedImplements &&
      selectedImplements.filter(
        implement =>
          ['John Deere', 'Frontier'].includes(implement.brand.name) &&
          isDefined(implement.msrp) &&
          implement.msrp >= 350
      ).length >= 2
    )
  },
})

export default hasTwoOrMoreImplementPromotionsSelector
