import { selector } from 'recoil'
import { optionState, optionsState } from '../state'

const SELECTED_OPTION_SELECTOR = 'SELECTED_OPTION_SELECTOR'

const selectedOptionSelector = selector({
  key: SELECTED_OPTION_SELECTOR,
  get: ({ get }) => {
    const option = get(optionState)
    const options = get(optionsState)

    const selectedOption = options.find(obj => obj._key === option)

    return selectedOption
  },
})

export default selectedOptionSelector
