import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const getErrorMessage = (type, fallback) => {
  switch (type) {
    case 'required':
      return 'This field is required.'
    case 'validate':
      return 'This field is invalid.'
    default:
      return fallback
  }
}

const FieldError = ({ children, defaultError, type, ...props }) => {
  if (!type && !children) {
    throw new Error('No error message provided.')
  }
  return <Error {...props}>{getErrorMessage(type, children)}</Error>
}

FieldError.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['required', 'validate']),
}

FieldError.defaultProps = {
  children: "There's a problem with this field.",
}

const Error = styled.span`
  color: var(--color-r100);
  display: block;
  font-size: 0.625rem;
  margin: var(--size-xxs) 0 0;
`

export default FieldError
