import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'
import Content from 'components/content'
import Disclaimer from 'components/disclaimer'
import OptionList from 'components/option-list/option-list'
import OptionListItem from 'components/option-list/option-list-item'
import Spacer from 'components/spacer'
import Button from 'components/button'
import { OPTIONS_PANEL, OPTIONS_TAB } from '../../constants'
import { isDefined, isNumber } from 'utils'
import { useOption, useTab } from '../../hooks'
import { optionsState, productDetailsState } from '../../state'
import { enabledTabsSelector, hasSelectedOptionSelector } from '../../selectors'

const OptionsPanel = () => {
  const { changeOption, option } = useOption()
  const hasSelectedOption = useRecoilValue(hasSelectedOptionSelector)
  const options = useRecoilValue(optionsState)
  const { tab, changeTab } = useTab()
  const [nextTab, setNextTab] = useState()
  const enabledTabs = useRecoilValue(enabledTabsSelector)
  const { moreDetailsLink } = useRecoilValue(productDetailsState)

  useEffect(() => {
    if (isDefined(enabledTabs)) {
      const currentTabIndex = enabledTabs.findIndex(obj => obj.tab === OPTIONS_TAB)
      setNextTab(enabledTabs[currentTabIndex + 1])
    }
  }, [enabledTabs])
  return (
    <StyledContent id={OPTIONS_PANEL} aria-labelledby={OPTIONS_TAB} hidden={tab !== OPTIONS_TAB}>
      <h2 className='mobile-hidden'>Options</h2>
      <Spacer className='mobile-hidden' />
      {/* TODO: Change title to reflect the option type (ex: Deck) */}
      <h3>Base option</h3>
      <Spacer size='s' />
      <OptionList>
        {options.map(opt => (
          <OptionListItem
            compareAtPrice={opt.msrp}
            compareAtPriceTooltip={
              isNumber(opt.msrp) && opt.msrp > opt.price
                ? "Manufacturer's Suggested Retail Price (MSRP)"
                : null
            }
            description={opt.buildAndPriceDescriptionHtml}
            images={opt.images}
            inputChangeHandler={changeOption}
            inputChangeValue={opt._key}
            inputChecked={opt._key === option}
            inputId={`radio-options-${opt._key}`}
            inputName='option'
            inputType='radio'
            moreDetailsLink={moreDetailsLink}
            price={isNumber(opt.advertisedPrice) ? opt.advertisedPrice : opt.price}
            title={opt.title}
            key={opt._key}
          />
        ))}
      </OptionList>
      <Spacer />
      {isDefined(nextTab) ? (
        <div className='next-button-container'>
          <Button
            aria-controls={nextTab.panel}
            onClick={() => changeTab(nextTab.tab)}
            ghost
            disabled={!hasSelectedOption}
          >
            Next: {nextTab.name}
          </Button>
        </div>
      ) : null}
      <Spacer size='l' />
      <Disclaimer />
    </StyledContent>
  )
}

const StyledContent = styled(Content)`
  .next-button-container {
    text-align: right;

    button {
      display: inline-block;
    }
  }
`

export default OptionsPanel
