import { atom } from 'recoil'

export const PANELS_REF_STATE = 'PANELS_REF_STATE'

const panelsRefState = atom({
  key: 'PANELS_REF_STATE',
  default: null,
})

export default panelsRefState
