import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

export const styledCheckboxInputClassName = 'styled-checkbox-input'
export const checkboxInputContainerClassName = 'checkbox-input-container'

const Checkbox = ({ checked, className, disabled, id, name, required, ...props }) => {
  return (
    <StyledCheckbox className={checkboxInputContainerClassName}>
      <input
        checked={checked}
        className={`visually-hidden ${className}`}
        disabled={disabled}
        id={id}
        name={name}
        required={required}
        type='checkbox'
        {...props}
      />
      <div className={styledCheckboxInputClassName} />
    </StyledCheckbox>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
}

Checkbox.defaultProps = {
  className: '',
  required: false,
}

const StyledCheckbox = styled.div`
  display: inline-block;

  input {
    :checked {
      ~ .${styledCheckboxInputClassName} {
        background-color: var(--color-g400);
        border-color: var(--color-g400);

        :after {
          display: block;
        }
      }
    }

    :focus,
    :hover {
      ~ .${styledCheckboxInputClassName} {
        outline: 1px solid Highlight;

        @media (-webkit-min-device-pixel-ratio: 0) {
          outline: 1px auto -webkit-focus-ring-color;
        }
      }
    }
  }

  .${styledCheckboxInputClassName} {
    background-color: #fff;
    border: 1px solid var(--color-n50);
    display: inline-block;
    height: 20px;
    position: relative;
    vertical-align: middle;
    width: 20px;

    :after {
      border: solid #fff;
      border-width: 0 2px 2px 0;
      content: '';
      display: none;
      height: 12px;
      left: 6px;
      position: absolute;
      top: 2px;
      transform: rotate(45deg);
      width: 6px;
    }
  }
`

export default Checkbox
