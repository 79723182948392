import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'
import Content from 'components/content'
import Disclaimer from 'components/disclaimer'
import OptionList from 'components/option-list/option-list'
import OptionListItem from 'components/option-list/option-list-item'
import OptionListSection from 'components/option-list/option-list-section'
import Spacer from 'components/spacer'
import Button from 'components/button'
import { isDefined } from 'utils'
import { FINANCING_PANEL, FINANCING_PROMOTION_DEFAULT, FINANCING_TAB } from '../../constants'
import { useFinancing, useTab } from '../../hooks'
import { financingOptionsState } from '../../state'
import { enabledTabsSelector, hasFinancingOptionsSelector } from '../../selectors'

const FinancingPanel = () => {
  const { financing, changeFinancing } = useFinancing()
  const financingOptions = useRecoilValue(financingOptionsState)
  const { tab, changeTab } = useTab()
  const [nextTab, setNextTab] = useState()
  const enabledTabs = useRecoilValue(enabledTabsSelector)

  useEffect(() => {
    if (isDefined(enabledTabs)) {
      const currentTabIndex = enabledTabs.findIndex(obj => obj.tab === FINANCING_TAB)
      setNextTab(enabledTabs[currentTabIndex + 1])
    }
  }, [enabledTabs])
  return (
    <StyledContent
      id={FINANCING_PANEL}
      aria-labelledby={FINANCING_TAB}
      hidden={tab !== FINANCING_TAB}
    >
      <h2>Financing</h2>
      <Spacer />
      <div className='option-grid'>
        <OptionListSection>
          <OptionList>
            <OptionListItem
              inputChangeHandler={changeFinancing}
              inputChangeValue={FINANCING_PROMOTION_DEFAULT}
              inputChecked={FINANCING_PROMOTION_DEFAULT === financing}
              inputId={`radio-financing-${FINANCING_PROMOTION_DEFAULT}`}
              inputName='financing'
              inputType='radio'
              showImage={false}
              title='I do not wish to finance'
            />
            {financingOptions.map(opt => (
              <OptionListItem
                inputChangeHandler={changeFinancing}
                inputChangeValue={opt._id}
                inputChecked={opt._id === financing}
                inputId={`radio-financing-${opt._id}`}
                inputName='financing'
                inputType='radio'
                showImage={false}
                title={opt.title}
                tooltip={opt.disclaimer}
                key={opt._id}
              />
            ))}
          </OptionList>
        </OptionListSection>
      </div>
      <Spacer />
      {isDefined(nextTab) ? (
        <div className='next-button-container'>
          <Button aria-controls={nextTab.panel} onClick={() => changeTab(nextTab.tab)} ghost>
            Next: {nextTab.name}
          </Button>
        </div>
      ) : null}
      <Spacer size='l' />
      <Disclaimer />
    </StyledContent>
  )
}

const StyledContent = styled(Content)`
  .next-button-container {
    text-align: right;

    button {
      display: inline-block;
    }
  }
`

export default props => {
  const hasFinancingOptions = useRecoilValue(hasFinancingOptionsSelector)
  return hasFinancingOptions ? <FinancingPanel {...props} /> : null
}
