import { atom } from 'recoil'

export const WARRANTY_OPTIONS_STATE = 'WARRANTY_OPTIONS_STATE'

const warrantyOptionsState = atom({
  key: WARRANTY_OPTIONS_STATE,
  default: [],
})

export default warrantyOptionsState
