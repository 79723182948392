import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { optionState } from '../state'

const useOption = () => {
  const [option, setOption] = useRecoilState(optionState)

  const changeOption = useCallback(
    val => {
      setOption(val)
    },
    [setOption]
  )

  return {
    changeOption,
    option,
  }
}

export default useOption
