import React from 'react'
import * as PropTypes from 'prop-types'
import Select from './select'
import { graphql, useStaticQuery } from 'gatsby'

const Location = ({ children, id, label, name, placeholder, register, required, ...props }) => {
  const { allSanityLocation } = useStaticQuery(query)
  return (
    <>
      <Select
        label={label}
        name={name}
        register={register}
        id={id}
        placeholder={placeholder}
        required={required}
        {...props}
      >
        {allSanityLocation.nodes.map(node => (
          <option value={node.value || node.title} key={node.title}>
            {node.title}
          </option>
        ))}
        {children}
      </Select>
    </>
  )
}

Location.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  register: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
}

Location.defaultProps = {
  id: 'preferred-hutson-location',
  label: 'Nearest Hutson Location',
  name: 'Nearest Hutson Location',
  placeholder: 'Select a location',
  required: false,
}

const query = graphql`
  {
    allSanityLocation(
      sort: { fields: title, order: ASC }
      filter: { retailLocation: { eq: true } }
    ) {
      nodes {
        title
        value: salesforceTitle
      }
    }
  }
`

export default Location
