import { atom } from 'recoil'

export const COMPATIBLE_ATTACHMENTS_STATE = 'COMPATIBLE_ATTACHMENTS_STATE'

const compatibleAttachmentsState = atom({
  key: COMPATIBLE_ATTACHMENTS_STATE,
  default: [],
})

export default compatibleAttachmentsState
