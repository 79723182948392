import { atom } from 'recoil'

export const ATTACHMENT_DISCOUNT_PROMOTIONS_STATE = 'ATTACHMENT_DISCOUNT_PROMOTIONS_STATE'

const attachmentDiscountPromotionsState = atom({
  key: ATTACHMENT_DISCOUNT_PROMOTIONS_STATE,
  default: [],
})

export default attachmentDiscountPromotionsState
