import { atom } from 'recoil'
import { OPTIONS_TAB } from '../../constants'

export const TAB_STATE = 'TAB_STATE'

const tabState = atom({
  key: TAB_STATE,
  default: OPTIONS_TAB,
})

export default tabState
