import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'
import Content from 'components/content'
import Disclaimer from 'components/disclaimer'
import OptionList from 'components/option-list/option-list'
import OptionListItem from 'components/option-list/option-list-item'
import OptionListSection from 'components/option-list/option-list-section'
import Spacer from 'components/spacer'
import Button from 'components/button'
import { isDefined } from 'utils'
import { formatWarranty } from '../../utils'
import { WARRANTY_PANEL, WARRANTY_TAB } from '../../constants'
import { useTab, useWarranty } from '../../hooks'
import { warrantyOptionsState } from '../../state'
import { enabledTabsSelector, hasWarrantyOptionsSelector } from '../../selectors'

const WarrantyPanel = () => {
  const { warranty, changeWarranty } = useWarranty()
  const warrantyOptions = useRecoilValue(warrantyOptionsState)
  const { tab, changeTab } = useTab()
  const [nextTab, setNextTab] = useState()
  const enabledTabs = useRecoilValue(enabledTabsSelector)

  useEffect(() => {
    if (isDefined(enabledTabs)) {
      const currentTabIndex = enabledTabs.findIndex(obj => obj.tab === WARRANTY_TAB)
      setNextTab(enabledTabs[currentTabIndex + 1])
    }
  }, [enabledTabs])
  return (
    <StyledContent id={WARRANTY_PANEL} aria-labelledby={WARRANTY_TAB} hidden={tab !== WARRANTY_TAB}>
      <h2>Warranty</h2>
      <Spacer />
      <div className='option-grid'>
        <OptionListSection>
          <OptionList>
            {warrantyOptions.map(opt => (
              <OptionListItem
                compareAtPrice={opt.compareAtPrice}
                compareAtPriceTooltip={
                  isDefined(opt.discountPromotion)
                    ? `${opt.discountPromotion.title}${
                        isDefined(opt.discountPromotion.subtitle)
                          ? ` ${opt.discountPromotion.subtitle}`
                          : ''
                      }. ${opt.discountPromotion.disclaimer}`
                    : null
                }
                description={opt.buildAndPriceDescriptionHtml}
                inputChangeHandler={changeWarranty}
                inputChangeValue={opt._key}
                inputChecked={opt._key === warranty}
                inputId={`radio-warranty-${opt._key}`}
                inputName='warranty'
                inputType='radio'
                moreDetailsLink={opt.type.link}
                price={opt.price}
                showImage={false}
                title={formatWarranty(opt)}
                key={opt._key}
              />
            ))}
          </OptionList>
        </OptionListSection>
      </div>
      <Spacer />
      {isDefined(nextTab) ? (
        <div className='next-button-container'>
          <Button aria-controls={nextTab.panel} onClick={() => changeTab(nextTab.tab)} ghost>
            Next: {nextTab.name}
          </Button>
        </div>
      ) : null}
      <Spacer size='l' />
      <Disclaimer />
    </StyledContent>
  )
}

const StyledContent = styled(Content)`
  .next-button-container {
    text-align: right;

    button {
      display: inline-block;
    }
  }
`

export default props => {
  const hasWarrantyOptions = useRecoilValue(hasWarrantyOptionsSelector)
  return hasWarrantyOptions ? <WarrantyPanel {...props} /> : null
}
