import { selector } from 'recoil'
import {
  hutsonSavingsSelector,
  selectedOptionSelector,
  selectedOptionPriceSelector,
  totalOptionDiscountsSelector,
} from '../'
import { isDefined, sum } from 'utils'

const HUTSON_PRICE_SELECTOR = 'HUTSON_PRICE_SELECTOR'

/**
 * Total Hutson price. Includes Hutson savings and all option discounts.
 */
const hutsonPriceSelector = selector({
  key: HUTSON_PRICE_SELECTOR,
  get: ({ get }) => {
    const selectedOption = get(selectedOptionSelector)
    const selectedOptionPrice = get(selectedOptionPriceSelector)

    if (!isDefined(selectedOption)) {
      return 0
    }

    const hutsonSavings = get(hutsonSavingsSelector)
    const totalOptionDiscounts = get(totalOptionDiscountsSelector)

    return [
      isDefined(selectedOption.msrp) && selectedOption.msrp > selectedOptionPrice
        ? selectedOption.msrp
        : selectedOptionPrice,
      -hutsonSavings,
      -totalOptionDiscounts,
    ].reduce(sum)
  },
})

export default hutsonPriceSelector
