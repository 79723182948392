import { atom } from 'recoil'

export const EQUIPMENT_MARGIN_STATE = 'EQUIPMENT_MARGIN_STATE'

const equipmentMarginState = atom({
  key: EQUIPMENT_MARGIN_STATE,
  default: null,
})

export default equipmentMarginState
