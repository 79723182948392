import { selector } from 'recoil'
import { sum } from 'utils'
import {
  cashPurchaseDiscountPromotionsState,
  discountPromotionsState,
  financingDiscountPromotionsState,
} from '../../state'
import {
  hasCashPurchaseDiscountPromotionsSelector,
  hasDiscountPromotionsSelector,
  hasFinancingDiscountPromotionsSelector,
} from '../'

const TOTAL_OPTION_DISCOUNTS_SELECTOR = 'TOTAL_OPTION_DISCOUNTS_SELECTOR'

/**
 * Total option discounts, including retail bonuses, cash purchase discounts, and financing discounts.
 */
const totalOptionDiscountsSelector = selector({
  key: TOTAL_OPTION_DISCOUNTS_SELECTOR,
  get: ({ get }) => {
    const hasDiscountPromotions = get(hasDiscountPromotionsSelector)
    const hasCashPurchaseDiscountPromotions = get(hasCashPurchaseDiscountPromotionsSelector)
    const hasFinancingDiscountPromotions = get(hasFinancingDiscountPromotionsSelector)
    const discountPromotions = get(discountPromotionsState)
    const cashPurchaseDiscountPromotions = get(cashPurchaseDiscountPromotionsState)
    const financingDiscountPromotions = get(financingDiscountPromotionsState)

    return [
      hasDiscountPromotions
        ? discountPromotions.reduce((total, promotion) => {
            return sum(total, promotion.details.discount)
          }, 0)
        : 0,
      hasCashPurchaseDiscountPromotions
        ? cashPurchaseDiscountPromotions.reduce((total, promotion) => {
            return sum(total, promotion.details.discount)
          }, 0)
        : 0,
      hasFinancingDiscountPromotions
        ? financingDiscountPromotions.reduce((total, promotion) => {
            return sum(total, promotion.details.discount)
          }, 0)
        : 0,
    ].reduce(sum)
  },
})

export default totalOptionDiscountsSelector
