import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { attachmentsState } from '../state'

const useAttachments = () => {
  const [attachments, setAttachments] = useRecoilState(attachmentsState)

  const changeAttachment = useCallback(
    val => {
      setAttachments(prevState => {
        // Check if attachment is already selected
        const matchIndex = Array.isArray(prevState) ? prevState.findIndex(id => id === val) : -1

        // If no match found, add attachment
        if (matchIndex === -1) {
          if (Array.isArray(prevState)) {
            return [...prevState, val]
          }
          return [val]
        }

        // Otherwise, remove the attachment
        const newState = [...prevState]
        newState.splice(matchIndex, 1)
        if (newState.length === 0) {
          return
        }
        return newState
      })
    },
    [setAttachments]
  )

  return {
    attachments,
    changeAttachment,
  }
}

export default useAttachments
