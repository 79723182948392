import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { warrantyState } from '../state'

const useWarranty = () => {
  const [warranty, setWarranty] = useRecoilState(warrantyState)

  const changeWarranty = useCallback(
    val => {
      setWarranty(val)
    },
    [setWarranty]
  )

  return {
    changeWarranty,
    warranty,
  }
}

export default useWarranty
