import { atom } from 'recoil'

export const DOWN_PAYMENT_STATE = 'DOWN_PAYMENT_STATE'

const downPaymentState = atom({
  key: DOWN_PAYMENT_STATE,
  default: 0,
})

export default downPaymentState
