import { useState } from 'react'
import { useEffectOnce } from '.'

const useNoSSR = () => {
  const [canRender, setCanRender] = useState(false)

  useEffectOnce(() => {
    setCanRender(true)
  })

  return canRender
}

export default useNoSSR
