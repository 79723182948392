import { selector } from 'recoil'
import { financingOptionsState } from '../state'

const HAS_FINANCING_OPTIONS_SELECTOR = 'HAS_FINANCING_OPTIONS_SELECTOR'

const hasFinancingOptionsSelector = selector({
  key: HAS_FINANCING_OPTIONS_SELECTOR,
  get: ({ get }) => {
    const financingOptions = get(financingOptionsState)
    return Array.isArray(financingOptions) && financingOptions.length > 0
  },
})

export default hasFinancingOptionsSelector
