import React, { memo } from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'
import UnstyledButton from 'components/unstyled-button'
import { isDefined } from 'utils'
import { OPTIONS_TAB } from '../../constants'
import { useTab } from '../../hooks'
import { optionState } from '../../state'
import { enabledTabsSelector } from '../../selectors'

const Tabs = () => {
  const enabledTabs = useRecoilValue(enabledTabsSelector)
  return (
    <StyledTabs id='tablist' role='tablist' aria-label='Page tabs'>
      {Array.isArray(enabledTabs)
        ? enabledTabs.map(obj => (
            <Tab id={obj.tab} name={obj.name} panel={obj.panel} key={obj.tab} />
          ))
        : null}
    </StyledTabs>
  )
}

const StyledTabs = styled.div`
  white-space: nowrap;
  overflow-x: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .tab {
    display: inline-block;
    border-right: 1px solid var(--color-n30);
    border-bottom: 4px solid #fff;
    box-sizing: border-box;
    padding: var(--size-s) var(--size-s) 14px;

    &[aria-selected='true'] {
      border-bottom: 4px solid var(--color-g400);
      font-weight: 600;
    }
  }

  @media print {
    display: none;
  }
`

const Tab = ({ id, name, panel }) => {
  const { tab, changeTab } = useTab()
  const option = useRecoilValue(optionState)
  return (
    <UnstyledButton
      id={id}
      className='tab'
      role='tab'
      onClick={() => changeTab(id)}
      aria-controls={panel}
      aria-selected={tab === id}
      key={id}
      disabled={id !== OPTIONS_TAB && !isDefined(option)}
    >
      {name}
    </UnstyledButton>
  )
}

export default memo(Tabs)
