import { createGlobalStyle } from 'styled-components'
import {
  OPTIONS_PANEL,
  IMPLEMENTS_PANEL,
  ATTACHMENTS_PANEL,
  FINANCING_PANEL,
  WARRANTY_PANEL,
  SUMMARY_PANEL,
} from '../constants'

const PrintStyles = createGlobalStyle`
  @media print {
    #${OPTIONS_PANEL},
    #${IMPLEMENTS_PANEL},
    #${ATTACHMENTS_PANEL},
    #${FINANCING_PANEL},
    #${WARRANTY_PANEL},
    footer {
      display: none !important;
    }

    #${SUMMARY_PANEL} {
      display: block !important;
    }

    html {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 12px;
      overflow-y: visible !important;
    }

    body {
      position: relative;
    }

    main {
      overflow-y: visible !important;
    }

    button {
      display: none !important;
    }
  }
`

export default PrintStyles
