import React from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'
import SidebarStandard from './sidebar-standard'
import SidebarSummary from './sidebar-summary'
import { SUMMARY_TAB } from '../../constants'
import { useTab } from '../../hooks'
import { productDetailsState } from '../../state'

const Sidebar = props => {
  const { isDesktop } = props
  const { tab } = useTab()
  const { title } = useRecoilValue(productDetailsState)
  return (
    <StyledSidebar hidden={!isDesktop && tab !== SUMMARY_TAB}>
      <h2 className='mobile-hidden'>Your {title}</h2>
      <h3 className='desktop-hidden mobile-font-size-h4'>Summary</h3>
      <SidebarSummary />
      <SidebarStandard />
    </StyledSidebar>
  )
}

const StyledSidebar = styled.div`
  background-color: #fff;
  border-left: 1px solid var(--color-n30);
  height: 100%;
  max-width: 400px;
  min-width: 400px;
  overflow-y: auto;
  padding: var(--size-l) var(--size-m);

  @media screen and (max-width: 899px) {
    background-color: inherit;
    border-left: none;
    height: auto;
    max-width: none;
    min-width: 0;
    overflow-y: visible;
  }

  @media screen and (min-width: 480px) and (max-width: 899px) {
    padding: var(--size-l) var(--size-m);
  }

  @media screen and (max-width: 479px) {
    padding: var(--size-m) var(--size-s);
  }

  @media print {
    border: none;
    display: block !important;
    max-width: none;
    overflow-y: visible;
    padding: 0;
    page-break-before: always;
    width: 100%;
  }
`

export default Sidebar
