import { selector } from 'recoil'
import {
  selectedAttachmentsDiscountSelector,
  selectedAttachmentsPriceSelector,
  selectedImplementsPriceSelector,
  selectedWarrantyDiscountSelector,
  selectedWarrantyPriceSelector,
} from '../'
import { sum } from 'utils'

const ADDITIONAL_OPTIONS_PRICE_SELECTOR = 'ADDITIONAL_OPTIONS_PRICE_SELECTOR'

/**
 * Price of selected implements, attachments, and warranty before discounts.
 */
const additionalOptionsPriceSelector = selector({
  key: ADDITIONAL_OPTIONS_PRICE_SELECTOR,
  get: ({ get }) => {
    const selectedAttachmentsPrice = get(selectedAttachmentsPriceSelector)
    const selectedAttachmentsDiscount = get(selectedAttachmentsDiscountSelector)
    const selectedImplementsPrice = get(selectedImplementsPriceSelector)
    const selectedWarrantyPrice = get(selectedWarrantyPriceSelector)
    const selectedWarrantyDiscount = get(selectedWarrantyDiscountSelector)

    const subtotal = [
      selectedAttachmentsPrice + selectedAttachmentsDiscount,
      selectedImplementsPrice,
      selectedWarrantyPrice + selectedWarrantyDiscount,
    ].reduce(sum)

    return subtotal
  },
})

export default additionalOptionsPriceSelector
