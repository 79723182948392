import { selector } from 'recoil'
import { sum } from 'utils'
import { selectedImplementsSelector } from '../'

const SELECTED_IMPLEMENTS_PRICE_SELECTOR = 'SELECTED_IMPLEMENTS_PRICE_SELECTOR'

/**
 * Total price of the selected implements before discounts.
 */
const selectedImplementsPriceSelector = selector({
  key: SELECTED_IMPLEMENTS_PRICE_SELECTOR,
  get: ({ get }) => {
    const selectedImplements = get(selectedImplementsSelector)

    if (Array.isArray(selectedImplements)) {
      return selectedImplements.reduce((total, implement) => {
        return sum(total, implement.price)
      }, 0)
    }

    return 0
  },
})

export default selectedImplementsPriceSelector
