import { selector } from 'recoil'
import { isDefined, isNumber, round } from 'utils'
import { hasSelectedWarrantySelector, selectedWarrantySelector } from '../'

const SELECTED_WARRANTY_DISCOUNT_SELECTOR = 'SELECTED_WARRANTY_DISCOUNT_SELECTOR'

/**
 * Total discount on the selected warranty.
 */
const selectedWarrantyDiscountSelector = selector({
  key: SELECTED_WARRANTY_DISCOUNT_SELECTOR,
  get: ({ get }) => {
    const selectedWarranty = get(selectedWarrantySelector)
    const hasSelectedWarranty = get(hasSelectedWarrantySelector)
    const hasDiscounts =
      hasSelectedWarranty &&
      isNumber(selectedWarranty.compareAtPrice) &&
      selectedWarranty.compareAtPrice > selectedWarranty.price &&
      isDefined(selectedWarranty.discountPromotion)

    return hasDiscounts ? round(selectedWarranty.compareAtPrice - selectedWarranty.price, 2) : 0
  },
})

export default selectedWarrantyDiscountSelector
