import { selector } from 'recoil'
import { isDefined } from 'utils'
import { attachmentDiscountPromotionsState } from '../state'
import { selectedAttachmentsSelector } from '.'
import { PERCENT_DISCOUNT_PROMOTION } from '../constants'

const SELECTED_ATTACHMENTS_DISCOUNTS_SELECTOR = 'SELECTED_ATTACHMENTS_DISCOUNTS_SELECTOR'

const selectedAttachmentsDiscountsSelector = selector({
  key: SELECTED_ATTACHMENTS_DISCOUNTS_SELECTOR,
  get: ({ get }) => {
    const selectedAttachments = get(selectedAttachmentsSelector)
    const attachmentDiscountPromotions = get(attachmentDiscountPromotionsState)

    if (
      Array.isArray(selectedAttachments) &&
      Array.isArray(attachmentDiscountPromotions) &&
      attachmentDiscountPromotions.length > 0
    ) {
      const discounts = selectedAttachments.reduce((acc, attachment) => {
        if (
          isDefined(attachment.discountId) &&
          attachment.discountPromotion.type === PERCENT_DISCOUNT_PROMOTION
        ) {
          // Check if discount already exists in acc
          const matchIndex = acc.findIndex(obj => obj._id === attachment.discountId)
          if (matchIndex === -1) {
            const promotionMatch = attachmentDiscountPromotions.find(
              obj => obj._id === attachment.discountId
            )
            acc.push({
              ...promotionMatch,
              totalDiscount: attachment.compareAtPrice - attachment.price,
            })
          } else {
            acc[matchIndex].totalDiscount += attachment.compareAtPrice - attachment.price
          }
        }
        return acc
      }, [])
      return discounts
    }

    return []
  },
})

export default selectedAttachmentsDiscountsSelector
