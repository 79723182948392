import React, { forwardRef } from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'

const InputBase = forwardRef(({ placeholder = ' ', type = 'text', ...props }, ref) => {
  return <StyledInput placeholder={placeholder} type={type} ref={ref} {...props} />
})

InputBase.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.oneOf([
    'button',
    'checkbox',
    'color',
    'date',
    'datetime-local',
    'email',
    'file',
    'hidden',
    'image',
    'month',
    'number',
    'password',
    'radio',
    'range',
    'reset',
    'search',
    'submit',
    'tel',
    'text',
    'time',
    'url',
    'week',
  ]),
}

const StyledInput = styled.input`
  background-color: #fff;
  border: 1px solid var(--color-n30);
  border-radius: 0;
  color: var(--color-n100);
  display: block;
  font-size: 1rem;
  padding: var(--size-xs);
  width: 100%;

  @media screen and (max-width: 899px) {
    padding: var(--size-s);
  }
`

export default InputBase
