import { useCallback } from 'react'
import { useRecoilState } from 'recoil'
import { downPaymentState } from '../state'

const useDownPayment = () => {
  const [downPayment, setDownPayment] = useRecoilState(downPaymentState)

  const changeDownPayment = useCallback(
    val => {
      setDownPayment(val)
    },
    [setDownPayment]
  )

  return {
    changeDownPayment,
    downPayment,
  }
}

export default useDownPayment
