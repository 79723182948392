import { selector } from 'recoil'
import { grandTotalSelector, hasSelectedFinancingSelector, selectedFinancingSelector } from '../'
import { pmt } from 'utils'

const PAYMENT_SELECTOR = 'PAYMENT_SELECTOR'

/**
 * Grand total payment amount based on selected financing.
 */
const paymentSelector = selector({
  key: PAYMENT_SELECTOR,
  get: ({ get }) => {
    const hasSelectedFinancing = get(hasSelectedFinancingSelector)
    const selectedFinancing = get(selectedFinancingSelector)
    const grandTotal = get(grandTotalSelector)

    const payment = hasSelectedFinancing
      ? pmt(selectedFinancing.details.rate, selectedFinancing.details.term, grandTotal)
      : null

    return payment
  },
})

export default paymentSelector
