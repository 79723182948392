import { selector } from 'recoil'
import { sum } from 'utils'
import { selectedAttachmentsSelector } from '../'

const SELECTED_ATTACHMENTS_PRICE_SELECTOR = 'SELECTED_ATTACHMENTS_PRICE_SELECTOR'

/**
 * Total price of the selected attachments including attachment discounts.
 */
const selectedAttachmentsPriceSelector = selector({
  key: SELECTED_ATTACHMENTS_PRICE_SELECTOR,
  get: ({ get }) => {
    const selectedAttachments = get(selectedAttachmentsSelector)

    if (Array.isArray(selectedAttachments)) {
      return selectedAttachments.reduce((total, attachment) => {
        return sum(total, attachment.price)
      }, 0)
    }

    return 0
  },
})

export default selectedAttachmentsPriceSelector
