import React from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'
import Disclaimer from 'components/disclaimer'
import Spacer from 'components/spacer'
import Button from 'components/button'
import SidebarSection from 'components/sidebar-section/sidebar-section'
import SidebarSectionHeader from 'components/sidebar-section/sidebar-section-header'
import SidebarSectionInner from 'components/sidebar-section/sidebar-section-inner'
import SidebarSubsection from 'components/sidebar-section/sidebar-subsection'
import { isDefined, isNumber, formatPrice } from 'utils'
import { formatWarranty } from '../../utils'
import {
  ATTACHMENTS_PANEL,
  ATTACHMENTS_TAB,
  FINANCING_PANEL,
  FINANCING_PROMOTION_DEFAULT,
  FINANCING_TAB,
  IMPLEMENTS_PANEL,
  IMPLEMENTS_TAB,
  OPTIONS_PANEL,
  OPTIONS_TAB,
  SUMMARY_PANEL,
  SUMMARY_TAB,
  WARRANTY_PANEL,
  WARRANTY_TAB,
} from '../../constants'
import { useAttachments, useFinancing, useImplements, useTab } from '../../hooks'
import {
  attachmentPickXGetYDiscountSelector,
  grandTotalSelector,
  hasCashPurchaseDiscountPromotionsSelector,
  hasCompatibleAttachmentOptionsSelector,
  hasCompatibleImplementOptionsSelector,
  hasDiscountPromotionsSelector,
  hasFinancingDiscountPromotionsSelector,
  hasFinancingOptionsSelector,
  hasSelectedFinancingSelector,
  hasSelectedOptionSelector,
  hasSelectedWarrantySelector,
  hasTwoOrMoreImplementPromotionsSelector,
  hasWarrantyOptionsSelector,
  hutsonPriceSelector,
  hutsonSavingsSelector,
  paymentSelector,
  selectedAttachmentsSelector,
  selectedFinancingSelector,
  selectedImplementsSelector,
  selectedOptionPriceSelector,
  selectedOptionSelector,
  selectedWarrantySelector,
} from '../../selectors'
import {
  attachmentPickXGetYDiscountPromotionsState,
  cashPurchaseDiscountPromotionsState,
  discountPromotionsState,
  financingDiscountPromotionsState,
  productDetailsState,
  tabState,
  twoOrMoreImplementPromotionsState,
} from '../../state'
import { downPaymentState } from '../../state'

const SidebarStandard = () => {
  const tab = useRecoilValue(tabState)
  return (
    <StyledSidebarStandard hidden={tab === SUMMARY_TAB}>
      <OptionsSection />
      <ImplementsSection />
      <AttachmentsSection />
      <WarrantySection />
      <FinancingSection />
      <SummarySection />
    </StyledSidebarStandard>
  )
}

const StyledSidebarStandard = styled.div`
  .none-selected {
    margin-top: 0;
  }

  .summary-price {
    font-size: 1.3125rem;
    font-weight: 600;
    margin: 0;

    .summary-price-append {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  @media print {
    display: none !important;
  }
`

const OptionsSection = () => {
  const hasSelectedOption = useRecoilValue(hasSelectedOptionSelector)
  const selectedOption = useRecoilValue(selectedOptionSelector)
  const selectedOptionPrice = useRecoilValue(selectedOptionPriceSelector)
  const { moreDetailsLink } = useRecoilValue(productDetailsState)
  const cashPurchaseDiscountPromotions = useRecoilValue(cashPurchaseDiscountPromotionsState)
  const discountPromotions = useRecoilValue(discountPromotionsState)
  const hasCashPurchaseDiscountPromotions = useRecoilValue(
    hasCashPurchaseDiscountPromotionsSelector
  )
  const financingDiscountPromotions = useRecoilValue(financingDiscountPromotionsState)
  const hasFinancingDiscountPromotions = useRecoilValue(hasFinancingDiscountPromotionsSelector)
  const hasDiscountPromotions = useRecoilValue(hasDiscountPromotionsSelector)
  const hutsonSavings = useRecoilValue(hutsonSavingsSelector)
  const hutsonPrice = useRecoilValue(hutsonPriceSelector)
  const { tab, changeTab } = useTab()
  const showDiscounts =
    hasDiscountPromotions || hasCashPurchaseDiscountPromotions || hutsonSavings !== 0
  const price = hasSelectedOption
    ? isDefined(selectedOption.msrp) && selectedOption.msrp !== 0
      ? selectedOption.msrp
      : selectedOptionPrice
    : 0
  const showHutsonPrice = hasSelectedOption && price !== hutsonPrice
  return (
    <SidebarSection>
      <SidebarSectionHeader
        buttonClickHandler={changeTab}
        buttonControls={OPTIONS_PANEL}
        buttonText='Edit'
        buttonValue={OPTIONS_TAB}
        showButton={hasSelectedOption && tab !== OPTIONS_TAB}
      >
        Options
      </SidebarSectionHeader>
      <Spacer size='xs' />
      {hasSelectedOption ? (
        <div>
          <SidebarSectionInner
            images={selectedOption.images}
            price={price}
            title={selectedOption.title}
            moreDetailsLink={moreDetailsLink}
          />
          {showDiscounts ? (
            <>
              <Spacer size='m' />
              <SidebarSubsection>
                {hutsonSavings !== 0 ? (
                  <SidebarSectionInner
                    price={-hutsonSavings}
                    showImage={false}
                    title='Hutson savings'
                  />
                ) : null}
                {hasDiscountPromotions
                  ? discountPromotions.map(promotion => (
                      <SidebarSectionInner
                        price={-promotion.details.discount}
                        showImage={false}
                        title={`${promotion.title}${
                          isDefined(promotion.subtitle) ? ` ${promotion.subtitle}` : ''
                        }`}
                        tooltip={promotion.disclaimer}
                        key={promotion._id}
                      />
                    ))
                  : null}
                {hasCashPurchaseDiscountPromotions
                  ? cashPurchaseDiscountPromotions.map(promotion => (
                      <SidebarSectionInner
                        price={-promotion.details.discount}
                        showImage={false}
                        title={`${promotion.title}${
                          isDefined(promotion.subtitle) ? ` ${promotion.subtitle}` : ''
                        }`}
                        tooltip={promotion.disclaimer}
                        key={promotion._id}
                      />
                    ))
                  : null}
                {hasFinancingDiscountPromotions
                  ? financingDiscountPromotions.map(promotion => (
                      <SidebarSectionInner
                        price={-promotion.details.discount}
                        showImage={false}
                        title={`${promotion.title}${
                          isDefined(promotion.subtitle) ? ` ${promotion.subtitle}` : ''
                        }`}
                        tooltip={promotion.disclaimer}
                        key={promotion._id}
                      />
                    ))
                  : null}
              </SidebarSubsection>
            </>
          ) : null}
          {showHutsonPrice ? (
            <>
              <Spacer size='m' />
              <SidebarSubsection>
                <SidebarSectionInner price={hutsonPrice} showImage={false} title='Hutson price' />
              </SidebarSubsection>
            </>
          ) : null}
        </div>
      ) : (
        <p className='none-selected'>No option selected.</p>
      )}
    </SidebarSection>
  )
}

const ImplementsSection = () => {
  const hasCompatibleImplementOptions = useRecoilValue(hasCompatibleImplementOptionsSelector)
  const hasSelectedOption = useRecoilValue(hasSelectedOptionSelector)
  const selectedImplements = useRecoilValue(selectedImplementsSelector)
  const hasTwoOrMoreImplementPromotions = useRecoilValue(hasTwoOrMoreImplementPromotionsSelector)
  const twoOrMoreImplementPromotions = useRecoilValue(twoOrMoreImplementPromotionsState)
  const { changeImplement } = useImplements()
  const { tab, changeTab } = useTab()

  const hasSelectedImplements = Array.isArray(selectedImplements) && selectedImplements.length > 0
  return hasCompatibleImplementOptions ? (
    <SidebarSection>
      <SidebarSectionHeader
        buttonClickHandler={changeTab}
        buttonControls={IMPLEMENTS_PANEL}
        buttonText={hasSelectedImplements ? 'Edit' : 'Add'}
        buttonValue={IMPLEMENTS_TAB}
        showButton={hasSelectedOption && tab !== IMPLEMENTS_TAB}
      >
        Implements
      </SidebarSectionHeader>
      <Spacer size='xs' />
      {hasSelectedOption ? (
        <div>
          {hasSelectedImplements ? (
            <>
              {selectedImplements.map(implement => (
                <SidebarSectionInner
                  images={implement.images}
                  moreDetailsLink={implement.link}
                  price={implement.price}
                  removeItem={() => changeImplement(implement.id)}
                  title={implement.title}
                  key={implement.id}
                />
              ))}
              {hasTwoOrMoreImplementPromotions ? (
                <>
                  <Spacer size='m' />
                  <SidebarSubsection>
                    {twoOrMoreImplementPromotions.map(promotion => (
                      <SidebarSectionInner
                        price={-promotion.details.discount}
                        showImage={false}
                        title={`${promotion.title}${
                          isDefined(promotion.subtitle) ? ` ${promotion.subtitle}` : ''
                        }`}
                        tooltip={promotion.disclaimer}
                        key={promotion._id}
                      />
                    ))}
                  </SidebarSubsection>
                </>
              ) : null}
            </>
          ) : (
            <>
              <p className='none-selected'>No implements selected.</p>
              <Button
                aria-controls={IMPLEMENTS_PANEL}
                onClick={() => changeTab(IMPLEMENTS_TAB)}
                ghost
              >
                Add implements
              </Button>
            </>
          )}
        </div>
      ) : (
        <p className='none-selected'>Please select an option.</p>
      )}
    </SidebarSection>
  ) : null
}

const AttachmentsSection = () => {
  const hasSelectedOption = useRecoilValue(hasSelectedOptionSelector)
  const selectedAttachments = useRecoilValue(selectedAttachmentsSelector)
  const hasCompatibleAttachmentOptions = useRecoilValue(hasCompatibleAttachmentOptionsSelector)
  const attachmentPickXGetYDiscount = useRecoilValue(attachmentPickXGetYDiscountSelector)
  const attachmentPickXGetYDiscountPromotions = useRecoilValue(
    attachmentPickXGetYDiscountPromotionsState
  )
  const { tab, changeTab } = useTab()
  const { changeAttachment } = useAttachments()

  const hasSelectedAttachments =
    Array.isArray(selectedAttachments) && selectedAttachments.length > 0
  return hasCompatibleAttachmentOptions ? (
    <SidebarSection>
      <SidebarSectionHeader
        buttonClickHandler={changeTab}
        buttonControls={ATTACHMENTS_PANEL}
        buttonText={hasSelectedAttachments ? 'Edit' : 'Add'}
        buttonValue={ATTACHMENTS_TAB}
        showButton={hasSelectedOption && tab !== ATTACHMENTS_TAB}
      >
        Attachments
      </SidebarSectionHeader>
      <Spacer size='xs' />
      {hasSelectedOption ? (
        <div>
          {hasSelectedAttachments ? (
            <>
              {selectedAttachments.map(attachment => (
                <SidebarSectionInner
                  images={attachment.images}
                  moreDetailsLink={attachment.link}
                  price={attachment.price}
                  removeItem={() => changeAttachment(attachment.id)}
                  title={attachment.title}
                  key={attachment.id}
                />
              ))}
              {attachmentPickXGetYDiscount !== 0 ? (
                <>
                  <Spacer size='m' />
                  <SidebarSubsection>
                    <SidebarSectionInner
                      price={-attachmentPickXGetYDiscount}
                      showImage={false}
                      title={`${attachmentPickXGetYDiscountPromotions[0].title}${
                        isDefined(attachmentPickXGetYDiscountPromotions[0].subtitle)
                          ? ` ${attachmentPickXGetYDiscountPromotions[0].subtitle}`
                          : ''
                      }`}
                      tooltip={attachmentPickXGetYDiscountPromotions[0].disclaimer}
                    />
                  </SidebarSubsection>
                </>
              ) : null}
            </>
          ) : (
            <>
              <p className='none-selected'>No attachments selected.</p>
              <Button
                aria-controls={ATTACHMENTS_PANEL}
                onClick={() => changeTab(ATTACHMENTS_TAB)}
                ghost
              >
                Add attachments
              </Button>
            </>
          )}
        </div>
      ) : (
        <p className='none-selected'>Please select an option.</p>
      )}
    </SidebarSection>
  ) : null
}

const WarrantySection = () => {
  const hasSelectedOption = useRecoilValue(hasSelectedOptionSelector)
  const hasWarrantyOptions = useRecoilValue(hasWarrantyOptionsSelector)
  const selectedWarranty = useRecoilValue(selectedWarrantySelector)
  const hasSelectedWarranty = useRecoilValue(hasSelectedWarrantySelector)
  const { tab, changeTab } = useTab()
  const showDiscounts =
    hasSelectedWarranty &&
    isNumber(selectedWarranty.compareAtPrice) &&
    selectedWarranty.compareAtPrice > selectedWarranty.price &&
    isDefined(selectedWarranty.discountPromotion)
  return hasWarrantyOptions ? (
    <SidebarSection>
      <SidebarSectionHeader
        buttonClickHandler={changeTab}
        buttonControls={WARRANTY_PANEL}
        buttonText='Edit'
        buttonValue={WARRANTY_TAB}
        showButton={hasSelectedOption && tab !== WARRANTY_TAB}
      >
        Warranty
      </SidebarSectionHeader>
      <Spacer size='xs' />
      {hasSelectedOption && hasSelectedWarranty ? (
        <div>
          <SidebarSectionInner
            moreDetailsLink={selectedWarranty.type.link}
            price={showDiscounts ? selectedWarranty.compareAtPrice : selectedWarranty.price}
            showImage={false}
            title={formatWarranty(selectedWarranty)}
          />
          {showDiscounts ? (
            <>
              <Spacer size='m' />
              <SidebarSubsection>
                <SidebarSectionInner
                  price={-(selectedWarranty.compareAtPrice - selectedWarranty.price)}
                  showImage={false}
                  title={`${selectedWarranty.discountPromotion.title}${
                    isDefined(selectedWarranty.discountPromotion.subtitle)
                      ? ` ${selectedWarranty.discountPromotion.subtitle}`
                      : ''
                  }`}
                  tooltip={selectedWarranty.discountPromotion.disclaimer}
                />
              </SidebarSubsection>
            </>
          ) : null}
          {selectedWarranty.isFactory ? (
            <>
              <Spacer size='s' />
              <Button aria-controls={WARRANTY_PANEL} onClick={() => changeTab(WARRANTY_TAB)} ghost>
                Add extended warranty
              </Button>
            </>
          ) : null}
        </div>
      ) : (
        <p className='none-selected'>Please select an option.</p>
      )}
    </SidebarSection>
  ) : null
}

const FinancingSection = () => {
  const hasSelectedOption = useRecoilValue(hasSelectedOptionSelector)
  const hasFinancingOptions = useRecoilValue(hasFinancingOptionsSelector)
  const selectedFinancing = useRecoilValue(selectedFinancingSelector)
  const downPayment = useRecoilValue(downPaymentState)
  const { financing } = useFinancing()
  const { tab, changeTab } = useTab()
  return hasFinancingOptions ? (
    <SidebarSection>
      <SidebarSectionHeader
        buttonClickHandler={changeTab}
        buttonControls={FINANCING_PANEL}
        buttonText={FINANCING_PROMOTION_DEFAULT === financing ? 'Add' : 'Edit'}
        buttonValue={FINANCING_TAB}
        showButton={hasSelectedOption && tab !== FINANCING_TAB}
      >
        Financing
      </SidebarSectionHeader>
      <Spacer size='xs' />
      {hasSelectedOption ? (
        <>
          {FINANCING_PROMOTION_DEFAULT !== financing ? (
            <div>
              <SidebarSectionInner
                showImage={false}
                title={selectedFinancing.title}
                tooltip={selectedFinancing.disclaimer}
              />
              {downPayment !== 0 ? (
                <>
                  <Spacer size='m' />
                  <SidebarSubsection>
                    <SidebarSectionInner
                      price={-downPayment}
                      showImage={false}
                      title='Down payment'
                    />
                  </SidebarSubsection>
                </>
              ) : null}
            </div>
          ) : (
            <>
              <p className='none-selected'>No financing selected.</p>
              <Button
                aria-controls={FINANCING_PANEL}
                onClick={() => changeTab(FINANCING_TAB)}
                ghost
              >
                Choose financing
              </Button>
            </>
          )}
        </>
      ) : (
        <p className='none-selected'>Please select an option.</p>
      )}
    </SidebarSection>
  ) : null
}

const SummarySection = () => {
  const hasSelectedOption = useRecoilValue(hasSelectedOptionSelector)
  const hasSelectedFinancing = useRecoilValue(hasSelectedFinancingSelector)
  const grandTotal = useRecoilValue(grandTotalSelector)
  const payment = useRecoilValue(paymentSelector)
  const { changeTab } = useTab()
  return (
    <SidebarSection>
      <SidebarSectionHeader showButton={false}>Summary</SidebarSectionHeader>
      <Spacer size='xs' />
      {hasSelectedOption ? (
        <div>
          <p className='summary-price'>
            {formatPrice(grandTotal)}
            {hasSelectedFinancing ? null : (
              <span className='summary-price-append'> as configured</span>
            )}
          </p>
          {hasSelectedFinancing ? (
            <p className='summary-price'>
              <span className='summary-price-append'>or </span>
              {formatPrice(payment)}/month
              <span className='summary-price-append'> as configured</span>
            </p>
          ) : null}
          <Spacer size='xs' />
          <Disclaimer>
            Taxes, setup, delivery, and fees not included. Prices and availability may vary.
          </Disclaimer>
          <Spacer size='s' />
          <Button aria-controls={SUMMARY_PANEL} onClick={() => changeTab(SUMMARY_TAB)} ghost>
            View full summary
          </Button>
        </div>
      ) : (
        <p className='none-selected'>Please select an option.</p>
      )}
    </SidebarSection>
  )
}

export default SidebarStandard
