import { useEffect } from 'react'

/**
 * Use an effect once
 * @param {function} effect
 */
const useEffectOnce = effect => {
  useEffect(effect, []) // eslint-disable-line
}

export default useEffectOnce
