import { atom } from 'recoil'

export const ATTACHMENTS_STATE = 'ATTACHMENTS_STATE'

const attachmentsState = atom({
  key: ATTACHMENTS_STATE,
  default: [],
})

export default attachmentsState
