import { atom } from 'recoil'

export const FINANCING_OPTIONS_STATE = 'FINANCING_OPTIONS_STATE'

const financingOptionsState = atom({
  key: FINANCING_OPTIONS_STATE,
  default: [],
})

export default financingOptionsState
