import { selector } from 'recoil'
import { isNumber, sum } from 'utils'
import { selectedAttachmentsSelector } from '../'

const SELECTED_ATTACHMENTS_DISCOUNT_SELECTOR = 'SELECTED_ATTACHMENTS_DISCOUNT_SELECTOR'

/**
 * Total amount of attachment discounts (positive).
 */
const selectedAttachmentsDiscountSelector = selector({
  key: SELECTED_ATTACHMENTS_DISCOUNT_SELECTOR,
  get: ({ get }) => {
    const selectedAttachments = get(selectedAttachmentsSelector)

    if (Array.isArray(selectedAttachments)) {
      return selectedAttachments.reduce((total, attachment) => {
        return isNumber(attachment.compareAtPrice) && attachment.compareAtPrice > attachment.price
          ? sum(total, attachment.compareAtPrice - attachment.price)
          : total
      }, 0)
    }

    return 0
  },
})

export default selectedAttachmentsDiscountSelector
