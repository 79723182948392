import { selector } from 'recoil'
import { selectedFinancingSelector } from '.'
import { isDefined } from 'utils'

const HAS_SELECTED_FINANCING_SELECTOR = 'HAS_SELECTED_FINANCING_SELECTOR'

const hasSelectedFinancingSelector = selector({
  key: HAS_SELECTED_FINANCING_SELECTOR,
  get: ({ get }) => {
    return isDefined(get(selectedFinancingSelector))
  },
})

export default hasSelectedFinancingSelector
