import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRecoilValue } from 'recoil'
import UnstyledButton from 'components/unstyled-button'
import Content from 'components/content'
import { formatPrice, isDefined } from 'utils'
import { SUMMARY_TAB } from '../constants'
import { productDetailsState } from '../state'
import { enabledTabsSelector, grandTotalSelector, hasSelectedOptionSelector } from '../selectors'
import { useTab } from '../hooks'

const BottomBar = () => {
  const { tab, changeTab } = useTab()
  const [nextTab, setNextTab] = useState()
  const hasSelectedOption = useRecoilValue(hasSelectedOptionSelector)
  const grandTotal = useRecoilValue(grandTotalSelector)
  const enabledTabs = useRecoilValue(enabledTabsSelector)
  const { title } = useRecoilValue(productDetailsState)

  useEffect(() => {
    if (isDefined(enabledTabs)) {
      const currentTabIndex = enabledTabs.findIndex(obj => obj.tab === tab)
      setNextTab(enabledTabs[currentTabIndex + 1])
    }
  }, [enabledTabs, tab])
  return (
    <>
      <BottomBarSpacer hidden={tab === SUMMARY_TAB} />
      <StyledBottomBar className='desktop-hidden' hidden={tab === SUMMARY_TAB}>
        <StyledContent>
          {hasSelectedOption ? (
            <>
              <div className='bottom-bar-text'>
                <p className='bottom-bar-title'>Your {title}</p>
                <p className='bottom-bar-price'>{formatPrice(grandTotal)}</p>
              </div>
              {isDefined(nextTab) ? (
                <div className='bottom-bar-next-button'>
                  <UnstyledButton
                    aria-controls={nextTab.panel}
                    onClick={() => changeTab(nextTab.tab)}
                  >
                    Next ›
                  </UnstyledButton>
                </div>
              ) : null}
            </>
          ) : (
            <p>Select an option to get started.</p>
          )}
        </StyledContent>
      </StyledBottomBar>
    </>
  )
}

const BottomBarSpacer = styled.div`
  height: 80px;
`

const StyledBottomBar = styled.div`
  background-color: #fff;
  border-top: 1px solid var(--color-n30);
  bottom: 0;
  height: 80px;
  position: fixed;
  width: 100%;
  z-index: 1;

  .bottom-bar-text {
    flex-grow: 2;
  }

  .bottom-bar-title {
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0;
  }

  .bottom-bar-price {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
  }

  .bottom-bar-next-button {
    button {
      color: var(--color-g400);
      font-weight: 700;
      padding: var(--size-xs);
    }
  }
`

const StyledContent = styled(Content)`
  align-items: center;
  display: flex;
  height: 100%;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
`

export default BottomBar
