import React, { memo } from 'react'
import styled from 'styled-components'
import { useSetRecoilState } from 'recoil'
import Tabs from './tabs'
import ImplementsPanel from './implements-panel'
import AttachmentsPanel from './attachments-panel'
import OptionsPanel from './options-panel'
import WarrantyPanel from './warranty-panel'
import FinancingPanel from './financing-panel'
import SummaryPanel from './summary-panel'
import { panelsRefState } from '../../state'

const MainContent = () => {
  const setPanelsRef = useSetRecoilState(panelsRefState)
  return (
    <StyledMainContent>
      <TabsContainer>
        <Tabs />
      </TabsContainer>
      <Panels ref={setPanelsRef} tabIndex='-1'>
        <OptionsPanel />
        <ImplementsPanel />
        <AttachmentsPanel />
        <WarrantyPanel />
        <FinancingPanel />
        <SummaryPanel />
      </Panels>
    </StyledMainContent>
  )
}

const StyledMainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  height: 100%;
  /* overflow-x: hidden; */

  @media screen and (max-width: 899px) {
    width: 100%;
  }

  @media print {
    height: auto;
  }
`

const TabsContainer = styled.div`
  background-color: #fff;
  border-bottom: 1px solid var(--color-n30);
  overflow-y: visible;

  @media screen and (max-width: 899px) {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  @media print {
    display: none;
  }
`

const Panels = styled.div`
  flex-grow: 2;
  height: 100%;
  overflow-y: auto;
  outline: none;

  @media screen and (max-width: 899px) {
    overflow-y: visible;
  }

  @media print {
    overflow-y: visible;
  }
`
const arePropsEqual = (prevProps, nextProps) => {
  return (
    prevProps.ocaEquipmentType === nextProps.ocaEquipmentType &&
    prevProps.sku === nextProps.sku &&
    prevProps.title === nextProps.title
  )
}

export default memo(MainContent, arePropsEqual)
