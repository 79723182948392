import React, { forwardRef } from 'react'
import styled from 'styled-components'

const SelectBase = forwardRef((props, ref) => {
  return <StyledSelect ref={ref} {...props} />
})

const StyledSelect = styled.select`
  background-color: #fff;
  border: 1px solid var(--color-n30);
  border-radius: 0;
  color: var(--color-n100);
  display: block;
  font-size: 1rem;
  padding: var(--size-xs);
  width: 100%;

  :first-child:disabled {
    color: var(--color-n50);
  }

  @media screen and (max-width: 899px) {
    padding: var(--size-s);
  }
`

export default SelectBase
