import { atom } from 'recoil'

export const OPTIONS_STATE = 'OPTIONS_STATE'

const optionsState = atom({
  key: OPTIONS_STATE,
  default: [],
})

export default optionsState
